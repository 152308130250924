import { MatchReport, Player } from "../api/types";
import { createContext, useContext, useEffect, useState } from "react";

import MutateMatchReportDialog from "../pages/scouting/Dialogs/MutateMatchReportDialog";
import QueryMatchReportDialog from "../pages/scouting/Dialogs/QueryMatchReportDialog";
import { SEARCH_PARAMS_REPORT_ID } from "../constants";
import { useMatchReport } from "../api/queries";
import { useSearchParams } from "react-router-dom";

interface MatchReportDialogProps {
  handleCreateReportClick: (player: Player | undefined) => void;
  handleReportClick: (report: MatchReport) => void;
}

const defaultState = {
  handleCreateReportClick: () => {},
  handleReportClick: () => {},
};

const MatchReportDialogContext =
  createContext<MatchReportDialogProps>(defaultState);

export const ScoutingReportDialogProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const matchReportId = Number(searchParams.get(SEARCH_PARAMS_REPORT_ID));

  // CONTEXT
  const [reportInContext, setReportInContext] = useState<MatchReport>();
  const [player, setPlayer] = useState<Player>();

  // STATE
  const [mutateDialogOpen, setMutateDialogOpen] = useState<boolean>(false);
  const [queryDialogOpen, setQueryDialogOpen] = useState<boolean>(false);

  const handleReportClick = (report: MatchReport) => {
    // console.debug(report);
    if (report) {
      searchParams.set(SEARCH_PARAMS_REPORT_ID, String(report.id));
      setSearchParams(searchParams);
    }
  };

  const handleCreateReportClick = (player: Player | undefined) => {
    searchParams.delete(SEARCH_PARAMS_REPORT_ID);
    setPlayer(player);
    setMutateDialogOpen(true);
  };

  const defaultValue = {
    handleCreateReportClick: handleCreateReportClick,
    handleReportClick: handleReportClick,
  };

  const matchReportQuery = useMatchReport(
    !!Number(searchParams.get(SEARCH_PARAMS_REPORT_ID)),
    matchReportId
  );

  useEffect(() => {
    if (matchReportQuery.data) {
      setReportInContext(matchReportQuery.data as MatchReport);
    }
  }, [matchReportQuery.data, setReportInContext]);

  useEffect(() => {
    if (reportInContext) {
      setQueryDialogOpen(true);
    }
  }, [reportInContext]);

  return (
    <MatchReportDialogContext.Provider value={defaultValue}>
      <MutateMatchReportDialog
        player={player}
        open={mutateDialogOpen}
        setOpen={setMutateDialogOpen}
        onClose={() => {
          setMutateDialogOpen(false);
        }}
      />

      <QueryMatchReportDialog
        player={player}
        open={queryDialogOpen}
        setOpen={setQueryDialogOpen}
        report={reportInContext}
        onClose={() => {
          setQueryDialogOpen(false);
          setReportInContext(undefined);
        }}
      />
      {children}
    </MatchReportDialogContext.Provider>
  );
};

export const useMatchReportDialogContext = () =>
  useContext(MatchReportDialogContext);
