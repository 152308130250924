import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
} from "react";

import { DepthChart } from "../api/types";
import { SEARCH_PARAMS_DEPTH_CHART_ID } from "../constants";
import { UseQueryResult } from "react-query";
import { useDepthChart } from "../api/queries";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";

interface DepthChartContextInterface {
  depthChartInContext: DepthChart | undefined;
  setDepthChartInContext: Dispatch<SetStateAction<DepthChart | undefined>>;
  depthChartQuery: UseQueryResult<DepthChart, unknown> | undefined;
  isLoadingDepthChart: boolean;
  setIsLoadingDepthChart: Dispatch<SetStateAction<boolean>>;
}

const defaultState = {
  depthChartInContext: undefined,
  setDepthChartInContext: () => {},
  depthChartQuery: undefined,
  isLoadingDepthChart: false,
  setIsLoadingDepthChart: () => {},
};

const DepthChartContext =
  createContext<DepthChartContextInterface>(defaultState);

export const DepthChartProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [searchParams] = useSearchParams();
  const [depthChartInContext, setDepthChartInContext] = useState<
    DepthChart | undefined
  >();
  const [isLoadingDepthChart, setIsLoadingDepthChart] =
    useState<boolean>(false);

  const depthChartQuery = useDepthChart(
    !!Number(searchParams.get(SEARCH_PARAMS_DEPTH_CHART_ID)),
    Number(searchParams.get(SEARCH_PARAMS_DEPTH_CHART_ID))
  );

  useEffect(() => {
    if (depthChartQuery.data) {
      // console.debug(depthChartResult.data);
      setDepthChartInContext(depthChartQuery.data as DepthChart);
      setIsLoadingDepthChart(false);
    }
  }, [depthChartQuery]);

  const value = {
    depthChartInContext: depthChartInContext,
    setDepthChartInContext: setDepthChartInContext,
    depthChartQuery: depthChartQuery,
    isLoadingDepthChart,
    setIsLoadingDepthChart,
  };

  return (
    <DepthChartContext.Provider value={value}>
      {children}
    </DepthChartContext.Provider>
  );
};

export const useDepthChartContext = () => useContext(DepthChartContext);
