import { CHICAGO_FIRE, CHICAGO_FIRE_2, SECOND_TEAM } from "../constants";
import { Dispatch, SetStateAction } from "react";
import {
  GET_AUTH,
  GET_CONSTANT_COUNTRIES,
  GET_CONSTANT_LEAGUES,
  GET_DEPTH_CHART,
  GET_DEPTH_CHARTS,
  GET_FOLDERS,
  GET_LIST,
  GET_LISTS,
  GET_MATCH_REPORT,
  GET_MATCH_REPORTS,
  GET_MATCH_REPORT_QUALITIES,
  GET_NOTES,
  GET_NOTIFICATIONS,
  GET_OPERATIONS_DATA,
  GET_PLAYER,
  GET_PLAYERS,
  GET_PLAYER_ATTRIBUTES,
  GET_PLAYER_DATABASE_META,
  GET_PLAYER_KPIS,
  GET_PLAYER_MATCH_REPORTS,
  GET_PLAYER_NOTES,
  GET_PLAYER_WEEKLY_STATS,
  GET_SCOUTED_PLAYERS,
  GET_SCOUTING_REPORTS,
  GET_SHARES,
  GET_TABLE_METRICS,
  GET_TEAM_STATS,
  GET_USERS,
  PLAYER_SEARCH,
} from "./keys";
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-premium";
import { Player, PlayerList } from "./types";

import { formatDateToLocalWithoutTime } from "../utils/dates";
import { useAPIContext } from "../contexts/APIContext";
import { useClubContext } from "../contexts/ClubContext";
import { useOktaAuth } from "@okta/okta-react";
import { useOktaAuthContext } from "../contexts/OktaAuthContext";
import { useQuery } from "react-query";
import { useTeamContext } from "../contexts/TeamContext";

export function useAuth() {
  const { authenticated, apiClient } = useAPIContext();
  const { email } = useOktaAuthContext();

  return useQuery(
    [GET_AUTH],
    async () => {
      return await apiClient.getAuth(email);
    },
    {
      enabled: !!email && authenticated,
    }
  );
}

export function useConstantUsers() {
  const { apiClient, authenticated } = useAPIContext();

  return useQuery(
    [GET_USERS],
    async () => {
      return await apiClient.getUsers();
    },
    {
      enabled: authenticated,
      staleTime: 0,
    }
  );
}

export function useConstantCountries() {
  const { apiClient, authenticated } = useAPIContext();

  return useQuery(
    [GET_CONSTANT_COUNTRIES],
    async () => {
      const schools = await apiClient.getConstantCountries();

      return schools;
    },
    {
      enabled: authenticated,
    }
  );
}

export function useConstantLeagues() {
  const { apiClient, authenticated } = useAPIContext();

  return useQuery(
    [GET_CONSTANT_LEAGUES],
    async () => {
      const schools = await apiClient.getConstantLeagues();

      return schools;
    },
    {
      enabled: authenticated,
    }
  );
}

export function useDepthChart(enabled: boolean, depthChartId: number) {
  const { apiClient, authenticated } = useAPIContext();

  return useQuery(
    [
      GET_DEPTH_CHART,
      {
        id: depthChartId,
      },
    ],
    async () => {
      return await apiClient.getDepthChart(depthChartId);
    },
    {
      enabled: enabled && authenticated,
    }
  );
}

export function useDepthCharts() {
  const { apiClient, authenticated } = useAPIContext();

  return useQuery(
    [GET_DEPTH_CHARTS],
    async () => {
      return await apiClient.getDepthCharts();
    },
    {
      enabled: authenticated,
    }
  );
}

export function useFolders(owner: string | undefined) {
  const { authenticated, apiClient } = useAPIContext();

  return useQuery(
    [GET_FOLDERS],
    async () => {
      if (owner) {
        return await apiClient.getFolders(owner);
      } else {
        return [];
      }
    },
    {
      enabled: authenticated,
    }
  );
}

export function useLeagueTable() {
  const { authenticated, apiClient } = useAPIContext();
  const { club } = useClubContext();
  const { team } = useTeamContext();
  const queryKey = [GET_TABLE_METRICS, { club: club, team: team }];

  return useQuery(
    queryKey,
    async () => {
      return await apiClient.getTeamTable(
        club === CHICAGO_FIRE && team === SECOND_TEAM ? CHICAGO_FIRE_2 : club
      );
    },
    {
      enabled: authenticated,
    }
  );
}

export function useList(
  mode: string,
  enabled: boolean,
  listId: number,
  filters: GridFilterModel | undefined,
  sortModel: GridSortModel | undefined,
  setListInContext?: Dispatch<SetStateAction<PlayerList | undefined>>
) {
  const { email } = useOktaAuthContext();
  const { authenticated, apiClient } = useAPIContext();

  return useQuery(
    [
      GET_LIST,
      {
        mode: mode,
        email: email,
        listId: listId,
        filters: filters,
        sortModel: sortModel,
      },
    ],
    async () => {
      const list = await apiClient.getList(
        mode,
        listId,
        email,
        filters,
        sortModel
      );
      list && setListInContext && setListInContext(list);
      return list;
    },
    {
      enabled:
        enabled &&
        authenticated &&
        filters !== undefined &&
        sortModel !== undefined,
    }
  );
}

export function useLists() {
  const { apiClient, authenticated } = useAPIContext();
  const { email } = useOktaAuthContext();

  return useQuery(
    [GET_LISTS, { email: email }],
    async () => {
      return await apiClient.getLists(email);
    },
    {
      enabled: email !== null && authenticated,
    }
  );
}

export function usePlayerProfileQualities(profile: string | undefined) {
  const { apiClient, authenticated } = useAPIContext();

  return useQuery(
    [GET_MATCH_REPORT_QUALITIES, { profile: profile }],
    async () => {
      return await apiClient.getPlayerProfileQualities(profile);
    },
    {
      enabled: profile != null && authenticated,
    }
  );
}

export function useMatchReport(enabled: boolean, reportId: number) {
  const { authenticated, apiClient } = useAPIContext();

  return useQuery(
    [GET_MATCH_REPORT],
    async () => {
      return await apiClient.getMatchReport(reportId);
    },
    {
      enabled: authenticated && enabled,
    }
  );
}

export function useMatchReports() {
  const { authenticated, apiClient } = useAPIContext();

  return useQuery(
    [GET_MATCH_REPORTS],
    async () => {
      return await apiClient.getMatchReports();
    },
    {
      enabled: authenticated,
    }
  );
}

export function useNotes() {
  const { authenticated, apiClient } = useAPIContext();

  return useQuery(
    [GET_NOTES],
    async () => {
      return await apiClient.getNotes();
    },
    {
      enabled: authenticated,
    }
  );
}

export function useOperationsData(club: string) {
  const { authenticated, apiClient } = useAPIContext();

  return useQuery(
    [GET_OPERATIONS_DATA, { club }],
    async () => {
      const data = await apiClient.getOperationsData(club);

      return data;
    },
    {
      enabled: authenticated,
    }
  );
}

export function usePlayer(enabled: boolean, playerId: number) {
  const { apiClient, authenticated } = useAPIContext();

  return useQuery(
    [GET_PLAYER, { playerId: playerId }],
    async () => {
      if (playerId) {
        return await apiClient.getPlayer(playerId);
      }
    },
    {
      enabled: enabled && authenticated,
    }
  );
}

export function usePlayerAttributes(playerId: number | undefined) {
  const { authenticated, apiClient } = useAPIContext();

  return useQuery(
    [GET_PLAYER_ATTRIBUTES, { playerId: playerId }],
    async () => {
      if (playerId) {
        return await apiClient.getPlayerAttributes(playerId);
      }
    },
    {
      enabled: authenticated,
    }
  );
}

export function usePlayerDatabaseMeta() {
  const { apiClient, authenticated } = useAPIContext();

  return useQuery(
    [GET_PLAYER_DATABASE_META],
    async () => {
      return await apiClient.getPlayerDatabaseMeta();
    },
    { enabled: authenticated }
  );
}

export function usePlayerKPIs(enabled: boolean, playerId: number) {
  const { authenticated, apiClient } = useAPIContext();

  return useQuery(
    [[GET_PLAYER_KPIS, playerId], { playerId: playerId }],
    async () => {
      if (enabled) {
        return await apiClient.getPlayerKPIs(playerId);
      }
    },
    {
      enabled: enabled && authenticated,
    }
  );
}

export function usePlayerMatchReports(playerId: number | undefined) {
  const { authenticated, apiClient } = useAPIContext();

  return useQuery(
    [GET_PLAYER_MATCH_REPORTS, { playerId: playerId }],
    async () => {
      if (playerId) {
        return await apiClient.getPlayerMatchReports(playerId);
      }
    },
    {
      enabled: authenticated,
    }
  );
}

export function usePlayerNotes(playerId: number | undefined) {
  const { apiClient, authenticated } = useAPIContext();

  return useQuery(
    [GET_PLAYER_NOTES, { playerId: playerId }],
    async () => {
      if (playerId) {
        return await apiClient.getPlayerNotes(playerId);
      }
    },
    {
      enabled: !!playerId && authenticated,
    }
  );
}

export function usePlayerDatabase(
  enabled: boolean,
  mode: string,
  pageNumber: number,
  pageSize: number,
  filters: GridFilterModel | undefined,
  sortModel: GridSortModel | undefined
) {
  const { authenticated, apiClient } = useAPIContext();

  return useQuery(
    [
      GET_PLAYERS,
      {
        pageNumber: pageNumber,
        pageSize: pageSize,
        filters: filters,
        sortModel: sortModel,
      },
    ],
    async () => {
      return await apiClient.getPlayers(
        mode,
        pageNumber,
        pageSize,
        filters,
        sortModel
      );
    },
    {
      enabled:
        enabled &&
        authenticated &&
        filters !== undefined &&
        sortModel !== undefined,
    }
  );
}

export function usePlayerRatings(enabled: boolean, playerId: number) {
  const { authenticated, apiClient } = useAPIContext();

  return useQuery(
    [GET_PLAYERS],
    async () => {
      return await apiClient.getPlayerRatings(playerId);
    },
    {
      enabled: enabled && authenticated,
    }
  );
}

export function usePlayerWeeklyStats(player: Player, metric: string) {
  const { authenticated, apiClient } = useAPIContext();

  return useQuery(
    [GET_PLAYER_WEEKLY_STATS, { playerId: player.id, metric: metric }],
    async () => {
      return await apiClient.getPlayersWeeklyStats(player, metric);
    },
    {
      enabled: authenticated,
    }
  );
}

export function useNotifications() {
  const { apiClient, authenticated } = useAPIContext();
  const { authState } = useOktaAuth();

  return useQuery(
    [GET_NOTIFICATIONS],
    async () => {
      return await apiClient.getNotifications();
    },
    {
      enabled: authenticated && authState?.isAuthenticated,
      // refetchOnWindowFocus: true,
      staleTime: 10 * 60,
    }
  );
}

export function useScoutedPlayers() {
  const { apiClient, authenticated } = useAPIContext();

  return useQuery(
    [GET_SCOUTED_PLAYERS],
    async () => {
      return await apiClient.getScoutedPlayers();
    },
    {
      enabled: authenticated,
    }
  );
}

export function useScoutingReports() {
  const { apiClient, authenticated } = useAPIContext();

  return useQuery(
    [GET_SCOUTING_REPORTS],
    async () => {
      return await apiClient.getScoutingReports();
    },
    {
      enabled: authenticated,
    }
  );
}

export function useShares(
  enabled: boolean,
  objectId: number | undefined,
  objectType: string
) {
  const { email } = useOktaAuthContext();
  const { authenticated, apiClient } = useAPIContext();

  return useQuery(
    [GET_SHARES, { email, objectId, objectType }],
    async () => {
      if (email && objectId) {
        return await apiClient.getShares(email, objectId, objectType);
      } else {
        return [];
      }
    },
    {
      enabled: enabled && authenticated,
      staleTime: 0,
    }
  );
}

export function usePlayerSearch(submittedParams: {
  query: string;
  by: string;
}) {
  const { authenticated, apiClient } = useAPIContext();

  return useQuery(
    [PLAYER_SEARCH, { submittedParams }],
    async ({ signal }) => {
      return await apiClient.searchPlayers(
        submittedParams.query,
        submittedParams.by,
        signal
      );
    },
    {
      enabled: authenticated && !!submittedParams.query && !!submittedParams.by,
    }
  );
}

export function useTeamMetrics(
  startDate: Date,
  endDate: Date,
  teamName: string
) {
  const { apiClient } = useAPIContext();

  const startDateCacheKey = formatDateToLocalWithoutTime(startDate);
  const endDateCacheKey = formatDateToLocalWithoutTime(endDate);

  return useQuery(
    [GET_TEAM_STATS, { startDateCacheKey, endDateCacheKey, teamName }],
    async () => {
      return await apiClient.getTeamStats(startDate, endDate, teamName);
    }
  );
}
