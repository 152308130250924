import { Droppable } from "@hello-pangea/dnd";

import { DepthChartPlayer } from "../../../api/types";
import { MenuList } from "@mui/material";
import PitchPlayerDraggableListItem from "./PitchPlayerDraggableListItem";
import { useThemeContext } from "../../../contexts/CustomThemeContext";

const PLAYERS_PER_POSITION = 5;
const PLAYER_IN_LIST_HEIGHT = 36;
const PLAYER_LIST_MAX_HEIGHT = PLAYER_IN_LIST_HEIGHT * PLAYERS_PER_POSITION;
const PLAYER_LIST_MAX_WIDTH = 250;
const WHITE = "#fff";

interface PitchPlayerDraggableListProps {
  depthChartId: number;
  position: string;
  players: DepthChartPlayer[] | undefined;
  editMode: boolean;
  updateDepthChartPlayer: (dcp: DepthChartPlayer) => void;
}

export default function PitchPlayerDraggableList(
  props: PitchPlayerDraggableListProps
) {
  // CONTEXTS
  const { theme } = useThemeContext();

  // VARIABLES
  const BORDER = `1px solid ${theme.palette.primary.main}`;

  return (
    <Droppable droppableId={props.position}>
      {(provided) =>
        (props.editMode || (props.players && props.players.length > 0)) && (
          <MenuList
            ref={provided.innerRef}
            {...provided.droppableProps}
            dense
            disablePadding
            disableListWrap={true}
            sx={{
              backgroundColor: WHITE,
              border: BORDER,
              borderRadius: 1,
              maxWidth: PLAYER_LIST_MAX_WIDTH,
              marginTop: 0.25,
              fontSize: 10,
              cursor: !props.editMode ? "pointer" : "grab",
              maxHeight: PLAYER_LIST_MAX_HEIGHT,
              overflowY:
                props.players && props.players.length > PLAYERS_PER_POSITION
                  ? "scroll"
                  : "auto",
            }}
          >
            {props.players &&
              props.players.map((p: DepthChartPlayer, index: number) => (
                <PitchPlayerDraggableListItem
                  depthChartId={props.depthChartId}
                  position={props.position}
                  player={p}
                  index={index}
                  editMode={props.editMode}
                  key={p.id}
                  height={PLAYER_IN_LIST_HEIGHT}
                  includeBorder={
                    props.players ? index !== props.players.length - 1 : false
                  }
                  updateDepthChartPlayer={props.updateDepthChartPlayer}
                />
              ))}
            {provided.placeholder}
          </MenuList>
        )
      }
    </Droppable>
  );
}
