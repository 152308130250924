import { Box } from "@mui/system";
import { CircularProgress } from "@mui/material";

interface LoadingSpinnerOverlayProps {
  height?: string;
  size?: number;
  disableShrink?: boolean;
}

export default function LoadingSpinnerOverlay(
  props: LoadingSpinnerOverlayProps
) {
  return (
    <Box
      sx={{
        height: props.height ? props.height : "50vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress
        size={props.size ? props.size : 60}
        thickness={2}
        disableShrink={props.disableShrink ? props.disableShrink : false}
      />
    </Box>
  );
}
