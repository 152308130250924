import {
  Box,
  Chip,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  alpha,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ChartDataset, ChartOptions } from "chart.js";
import { PHASE_ABBRS, STAT_INFO, THREE_SIXTY_STAT_INFO } from "../constants";
import { Player, PlayerKPIs, Stat } from "../api/types";
import { SyntheticEvent, useEffect, useState } from "react";
import { UseQueryResult, useQueries } from "react-query";

import BarChartIcon from "@mui/icons-material/BarChart";
import { EmptyStateOverlay } from "./EmptyStateOverlay";
import { GET_PLAYER_KPIS } from "../api/keys";
import LoadingSpinnerOverlay from "./LoadingSpinnerOverlay";
import PlayerMetricsBarChart from "./PlayerMetricsBarChart";
import PlayerMetricsPolarChart from "./PlayerMetricsPolarChart";
import PlayerMetricsRadarChart from "./PlayerMetricsRadarChart";
import PlayerQuickSearch from "./PlayerQuickSearch";
import { PolarIcon } from "../components/CustomIcons";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { RadarIcon } from "../components/CustomIcons";
import { includesIgnoreCaseAndDiacritics } from "../utils/stringManipulation";
import { ordinalSuffixOf } from "../pages/scouting/utils";
import { useAPIContext } from "../contexts/APIContext";
import { usePlayerContext } from "../contexts/PlayerContext";

const currentYear = new Date().getFullYear().toString();

const ITEM_HEIGHT = 32;
const ITEM_PADDING_TOP = 4;
const MenuProps = {
  fontSize: 10,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CustomChip = styled(Chip)(() => ({
  height: "100%",
  display: "flex",
  flexDirection: "row",
  fontSize: "8pt",
  "& .MuiChip-label": {
    overflowWrap: "break-word",
    whiteSpace: "normal",
    textOverflow: "clip",
  },
}));

const sortStats = (stats: Stat[]): Stat[] => {
  return stats.sort((a: any, b: any) => {
    if (a.phase === b.phase) {
      return a.metric < b.metric ? -1 : 1;
    } else {
      return a.phase < b.phase ? -1 : 1;
    }
  });
};

const PlayerMetrics = () => {
  const { apiClient } = useAPIContext();
  const theme = useTheme();
  const { playerInContext } = usePlayerContext();
  const isScreenBelowMD = useMediaQuery(theme.breakpoints.down("md"));
  const isScreenAboveXL = useMediaQuery(theme.breakpoints.up("xl"));
  const FONT_SIZE = isScreenBelowMD ? 10 : 14;

  const [chartType, setChartType] = useState<
    "bar" | "radar" | "polarArea" | "horizontalbar"
  >("radar");
  const [phases] = useState<string[]>(Object.keys(PHASE_ABBRS));
  const [selectedPhase, setSelectedPhase] = useState<string>("DEFENDING");
  const [playerInComparison, setPlayerInComparison] = useState<Player>();

  const [datasets, setDatasets] = useState<
    ChartDataset<"radar" | "bar" | "polarArea", number[]>[]
  >([]);
  const [tooltipExplanations, setTooltipExplanations] = useState<string[]>([]);
  const [statLabels, setStatsLabels] = useState<string[]>([]);

  const playersKPIsQueries: UseQueryResult[] = useQueries<PlayerKPIs[]>(
    [playerInContext?.id, playerInComparison?.id]
      .filter((id: number | undefined) => id !== undefined)
      .map((playerId: number | undefined) => {
        return {
          enabled: playerId !== undefined,
          queryKey: [GET_PLAYER_KPIS, playerId],
          queryFn: () => apiClient.getPlayerKPIs(playerId),
        };
      })
  );

  const [someDataExists, setSomeDataExists] = useState<boolean>(
    playersKPIsQueries.some((playerKPIs: UseQueryResult) => {
      const d = playerKPIs.data as PlayerKPIs;
      return d?.name !== null;
    })
  );

  const handlePhaseChange = (
    event: SyntheticEvent<Element, Event>,
    value: any
  ) => {
    if (value !== null) {
      setSelectedPhase(value);
    }
  };

  const handleChargeTypeChange = (
    chartType: "bar" | "radar" | "polarArea" | "horizontalbar"
  ) => {
    if (chartType !== null) {
      setChartType(chartType);
    }
  };

  const handleLegendItemsChange = (event: any) => {
    // On autofill we get a stringified value.
    const newDatasets: ChartDataset<"radar" | "bar" | "polarArea", number[]>[] =
      [];
    datasets.forEach((dataset) => {
      if (dataset.label && event.target.value.includes(dataset.label)) {
        dataset.hidden = false;
      } else {
        dataset.hidden = true;
      }
      newDatasets.push(dataset);
    });
    setDatasets(newDatasets);
  };

  const handleLegendItemDelete = (value: string | undefined) => {
    if (value) {
      const newDatasets = [];
      for (const dataset of datasets) {
        if (dataset.label === value) {
          dataset.hidden = !dataset.hidden;
        }

        newDatasets.push(dataset);
      }
      setDatasets(newDatasets);
    }
  };

  function getStyles() {
    return {
      fontSize: "8pt",
    };
  }

  const dataIsFetched = playersKPIsQueries
    .map((playerKPIs) => playerKPIs.isFetched)
    .every((value) => value === true);

  useEffect(() => {
    // WARNING: HORRIBLE CODE AHEAD
    if (dataIsFetched) {
      let i = 0;
      const newDataSets: any = [];
      let newStatLabels: string[] = [];
      let newToolTipExplanations: string[] = [];

      for (const playerKPIsQueryResult of playersKPIsQueries) {
        const playerKPIs = playerKPIsQueryResult.data as PlayerKPIs;

        if (playerKPIs) {
          const playerName = playerKPIs.name;

          for (const competition of Object.keys(playerKPIs["data"])) {
            const seasons = Object.keys(playerKPIs["data"][competition]);

            for (const season of seasons) {
              // console.debug(season);
              const teams = Object.keys(
                playerKPIs["data"][competition][season]
              );

              for (const team of teams) {
                const positions = Object.keys(
                  playerKPIs["data"][competition][season][team]
                );

                for (const position of positions) {
                  const m =
                    playerKPIs["data"][competition][season][team][position][
                      "minutes"
                    ];
                  const minutes = Math.floor(m ? m : 0);
                  const datasetLabel = `${playerName} | ${competition} | ${season} | ${team} | ${position} | ${minutes} minutes`;
                  const positionalPhases = Object.keys(
                    playerKPIs["data"][competition][season][team][position][
                      "phases"
                    ]
                  );

                  // console.debug(props.phase);
                  // console.debug(positionalPhases);
                  if (positionalPhases.includes(selectedPhase)) {
                    const kpis =
                      playerKPIs["data"][competition][season][team][position][
                        "phases"
                      ][selectedPhase];

                    // Filter out 360 stats unless it's MLS or SSL (we only pay for those)
                    // We need to append 360 stats for MLS and SSL seasons so that the order is maintained
                    // so that the labels also have the same order
                    let sortedAndFilteredStats = sortStats(
                      kpis.filter((kpi: Stat) => {
                        return (
                          kpi.phase === selectedPhase &&
                          !Object.keys(THREE_SIXTY_STAT_INFO).includes(
                            kpi.metric
                          )
                        );
                      })
                    );

                    // console.debug(sortedAndFilteredStats);
                    sortedAndFilteredStats = [
                      ...sortedAndFilteredStats,
                      ...kpis.filter((kpi: Stat) => {
                        // console.debug(selectedPhase)
                        // console.debug(kpi.phase)
                        return (
                          kpi.phase === selectedPhase &&
                          Object.keys(THREE_SIXTY_STAT_INFO).includes(
                            kpi.metric
                          ) &&
                          ["Major League Soccer", "Super League"].includes(
                            competition
                          )
                        );
                      }),
                    ];

                    const sortedLabels = sortedAndFilteredStats.map(
                      (stat: Stat) => {
                        return isScreenBelowMD
                          ? STAT_INFO[stat.metric].label
                          : STAT_INFO[stat.metric].elongated;
                      }
                    );
                    const tooltipExplanations = sortedAndFilteredStats.map(
                      (stat: Stat) => {
                        return STAT_INFO[stat.metric].explanation;
                      }
                    );

                    if (sortedLabels.length > newStatLabels.length) {
                      newStatLabels = sortedLabels;
                      newToolTipExplanations = tooltipExplanations;
                    }

                    if (
                      !someDataExists &&
                      kpis.some((kpi) => kpi.percentile !== null)
                    ) {
                      setSomeDataExists(true);
                    }

                    const data = sortedAndFilteredStats.map(
                      (stat) => stat.percentile
                    );
                    const color =
                      i === 0
                        ? theme.palette.info.main
                        : theme.palette.secondary.main;
                    const borderColor = alpha(color, 1);
                    const backgroundColor = alpha(color, 0.1);
                    const hoverBackgroundColor = alpha(color, 0.6);

                    newDataSets.push({
                      hidden: !datasets.some(
                        (dataset) =>
                          dataset?.label?.includes(datasetLabel) &&
                          !dataset.hidden
                      ),
                      label: datasetLabel,
                      data: data,
                      backgroundColor: backgroundColor,
                      borderColor: borderColor,
                      borderWidth: 3,
                      hoverBackgroundColor: hoverBackgroundColor,
                    });
                  }
                }
              }
            }
          }
        }
        i = i + 1;
      }

      // This code gives me great pain, I'm sorry to all in the future who may have to deal with it
      // For each player, filter their datasets and see if all are hidden
      // In the case that they have no data displaying be default:

      // Find the most recent dataset (current year) with the highest number of
      // minutes. This is a heuristic for finding a reasonable default
      // competition/season to display
      for (const p of [playerInContext, playerInComparison]) {
        if (p === undefined) {
          continue;
        }

        const noDatasetsVisible = newDataSets
          .filter(
            (dataset: any) =>
              includesIgnoreCaseAndDiacritics(dataset?.label, p?.full_name) ||
              includesIgnoreCaseAndDiacritics(dataset?.label, p?.name)
          )
          .every((dataset: any) => {
            // console.debug(`${dataset?.label} : ${dataset?.hidden}`)
            return dataset.hidden;
          });

        // console.debug(`${p?.name} | no datasets visible: ${noDatasetsVisible}`)

        if (p && noDatasetsVisible) {
          // Find the base case dataset (current year + highest number of minutes) we want to display by default
          let index = 0;
          let mostMinutesInSeason = -Infinity;
          let largestIndex = 0;

          // Yay: good old fashioned find largest
          try {
            for (const d of newDataSets) {
              // console.debug(d?.label?.split('|').pop().trim().split(' ')[0]?.trim())
              const minutes = Number(
                d?.label?.split("|").pop().trim().split(" ")[0]?.trim()
              );
              // console.debug(`label: ${d?.label}\nname: ${p.full_name}\nminutes: ${minutes}\nmostMinutesInSeason: ${mostMinutesInSeason}`)
              // console.debug(includesIgnoreCaseAndDiacritics(d?.label, p.full_name))
              // console.debug(includesIgnoreCaseAndDiacritics(d?.label, p.name))
              // console.debug(d?.label?.includes(currentYear))

              if (
                (includesIgnoreCaseAndDiacritics(d?.label, p?.full_name) ||
                  includesIgnoreCaseAndDiacritics(d?.label, p?.name)) &&
                d?.label?.includes(currentYear) &&
                (mostMinutesInSeason === -Infinity ||
                  minutes > mostMinutesInSeason)
              ) {
                // console.debug(d?.label)
                mostMinutesInSeason = minutes;
                largestIndex = index;
              }

              index += 1;
            }
          } catch (e) {
            console.error(e);
          }

          if (newDataSets[largestIndex]) {
            newDataSets[largestIndex].hidden = false;
          }
        }
      }

      // console.debug(newDataSets)
      setDatasets(newDataSets);
      setStatsLabels(newStatLabels);
      setTooltipExplanations(newToolTipExplanations);
    }
  }, [
    dataIsFetched,
    playerInComparison,
    selectedPhase,
    datasets,
    isScreenBelowMD,
    playerInContext,
    playersKPIsQueries,
    someDataExists,
    theme.palette.info.main,
    theme.palette.secondary.main,
  ]);

  let scales;

  if (chartType === "radar") {
    scales = {
      r: {
        max: 100,
        min: 0,
        pointLabels: {
          font: {
            size: FONT_SIZE,
          },
        },
      },
    };
  }
  if (chartType === "bar") {
    scales = {
      x: {
        beginAtZero: true,
        max: 100,
        min: 0,
        ticks: {
          autoSkip: false,
          maxRotation: 45,
          minRotation: 45,
          // Include a dollar sign in the ticks
          callback: function (value: any, index: any) {
            return statLabels[index];
          },
        },
      },
      y: {
        max: 100,
        min: 0,
        beginAtZero: true,
        chartTitleText: {
          display: true,
          text: "Percentile",
          font: {
            size: FONT_SIZE,
          },
        },
      },
    };
  }
  if (chartType === "polarArea") {
    scales = {
      r: {
        max: 100,
        min: 0,
        pointLabels: {
          display: true,
          centerPointLabels: true,
          font: {
            size: FONT_SIZE,
          },
        },
      },
    };
  }

  const chartOptions = {
    animation: false,
    responsive: true,
    interaction: {
      intersect: chartType === "radar" ? false : true,
    },
    maintainAspectRatio: !isScreenBelowMD,
    elements: {
      point: {
        radius: 4,
        hoverRadius: 7,
      },
    },
    plugins: {
      colors: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      title: {
        color: theme.palette.primary.main,
        font: {
          size: FONT_SIZE,
          weight: 400,
        },
      },
      tooltip: {
        titleFont: {
          size: FONT_SIZE,
        },
        bodyFont: {
          size: FONT_SIZE,
        },
        footerFont: {
          size: FONT_SIZE,
        },
        callbacks: {
          afterTitle: function (tooltipItem: any) {
            return `${tooltipExplanations[tooltipItem[0].dataIndex]}`;
          },
          beforeBody: function (tooltipItem: any) {
            return `${ordinalSuffixOf(
              tooltipItem[0].formattedValue
            )} percentile`;
          },
          label: function (context: any) {
            // console.debug(context);
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed.r !== null) {
              label += `${ordinalSuffixOf(context.parsed.r)} percentile`;
            }
            return label;
          },
        },
      },
    },
    scales: scales,
    indexAxis: chartType === "bar" ? "x" : "y",
  } as ChartOptions;

  // console.debug(datasets.filter((dataset) => !dataset.hidden).map((dataset) => dataset.label))

  return (
    <>
      <FormControl size="small" fullWidth>
        <InputLabel id="demo-multiple-legend-items-label">
          competitions | seasons
        </InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          value={datasets
            .filter((dataset) => !dataset.hidden)
            .map((dataset) => dataset.label)}
          onChange={handleLegendItemsChange}
          input={<OutlinedInput label="competitions | seasons" />}
          MenuProps={MenuProps}
          size="small"
          renderValue={(values) => (
            <Box
              p={0.5}
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                flexWrap: "nowrap",
                gap: 0.5,
              }}
            >
              {values.map((value) => (
                <CustomChip
                  key={value}
                  label={value}
                  onMouseDown={(event: any) => {
                    event.stopPropagation();
                  }}
                  onClick={() => handleLegendItemDelete(value)}
                  onDelete={() => handleLegendItemDelete(value)}
                  size="small"
                  // color="secondary"
                  variant="outlined"
                />
              ))}
            </Box>
          )}
        >
          {datasets.map((dataset) => (
            <MenuItem
              key={dataset.label}
              value={dataset.label}
              style={getStyles()}
            >
              {dataset.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Stack
        direction={isScreenBelowMD ? "column" : "row"}
        display="flex"
        justifyContent="space-between"
        alignContent="center"
        alignItems="center"
        width="100%"
        mt={2}
        spacing={1}
      >
        {playerInContext && (
          <PlayerQuickSearch
            player={playerInContext}
            playerInComparison={playerInComparison}
            setPlayerInComparison={setPlayerInComparison}
          />
        )}

        <ToggleButtonGroup
          color="secondary"
          value={selectedPhase}
          exclusive
          onChange={handlePhaseChange}
          size="medium"
        >
          {phases &&
            Array.from(phases).map((phase: string) => (
              <ToggleButton
                key={phase}
                sx={{
                  padding: 1.5,
                }}
                value={phase}
              >
                {isScreenAboveXL ? phase : PHASE_ABBRS[phase]}
              </ToggleButton>
            ))}
        </ToggleButtonGroup>

        <ToggleButtonGroup
          color="secondary"
          value={chartType}
          exclusive
          onChange={(
            event: any,
            value: "bar" | "radar" | "polarArea" | "horizontalbar"
          ) => handleChargeTypeChange(value)}
          size="small"
          sx={{
            height: "54px",
          }}
        >
          <ToggleButton sx={{ padding: 1.4 }} key="polarArea" value="polarArea">
            <PolarIcon />
          </ToggleButton>
          <ToggleButton sx={{ padding: 1.4 }} key="radar" value="radar">
            <RadarIcon />
          </ToggleButton>
          <ToggleButton sx={{ padding: 1.4 }} key="bar" value="bar">
            <BarChartIcon />
          </ToggleButton>
          <ToggleButton
            sx={{ padding: 1.4 }}
            key="horizontalbar"
            value="horizontalbar"
          >
            <BarChartIcon sx={{ transform: "rotate(90deg)" }} />
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>

      <Container
        disableGutters
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "start",
          marginTop: 2,
        }}
      >
        {!dataIsFetched && <LoadingSpinnerOverlay />}

        {dataIsFetched &&
          (!someDataExists || playersKPIsQueries.length === 0) && (
            <EmptyStateOverlay
              content="No data found"
              height="20vh"
              icon={
                <QueryStatsIcon
                  sx={{ opacity: 0.26, width: "4rem", height: "4rem" }}
                />
              }
            />
          )}

        {dataIsFetched && someDataExists && chartType === "radar" && (
          <PlayerMetricsRadarChart
            chartOptions={chartOptions as ChartOptions<"radar">}
            datasets={datasets as ChartDataset<"radar", number[]>[]}
            statLabels={statLabels}
          />
        )}

        {dataIsFetched &&
          someDataExists &&
          (chartType === "bar" || chartType === "horizontalbar") && (
            <PlayerMetricsBarChart
              chartOptions={chartOptions as ChartOptions<"bar">}
              datasets={datasets as ChartDataset<"bar", number[]>[]}
              statLabels={statLabels}
            />
          )}

        {dataIsFetched && someDataExists && chartType === "polarArea" && (
          <PlayerMetricsPolarChart
            chartOptions={chartOptions as ChartOptions<"polarArea">}
            datasets={datasets as ChartDataset<"polarArea", number[]>[]}
            statLabels={statLabels}
          />
        )}
      </Container>
    </>
  );
};

export default PlayerMetrics;
