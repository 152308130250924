import {
  CREATE_DEPTH_CHART,
  CREATE_DEPTH_CHART_PLAYER,
  CREATE_FOLDER,
  CREATE_LIST,
  CREATE_MATCH_REPORT,
  CREATE_NOTE,
  CREATE_PLAYER,
  CREATE_PLAYER_ATTRIBUTES,
  CREATE_SCOUTED_PLAYER,
  CREATE_SCOUTING_REPORT,
  DELETE_DEPTH_CHART_PLAYER,
  DELETE_FOLDER,
  DELETE_LIST,
  DELETE_SCOUTING_REPORT,
  GET_DEPTH_CHART,
  GET_DEPTH_CHARTS,
  GET_FOLDERS,
  GET_LIST,
  GET_LISTS,
  GET_NOTIFICATIONS,
  GET_PLAYER,
  GET_PLAYERS,
  GET_PLAYER_ATTRIBUTES,
  GET_PLAYER_MATCH_REPORTS,
  GET_PLAYER_NOTES,
  GET_PLAYER_SCOUTING_REPORTS,
  GET_SCOUTED_PLAYERS,
  GET_SCOUTING_REPORTS,
  GET_SHARES,
  UPDATE_DEPTH_CHART,
  UPDATE_DEPTH_CHART_PLAYER,
  UPDATE_DEPTH_CHART_POSITION_LIST,
  UPDATE_FOLDER,
  UPDATE_LIST,
  UPDATE_LIST_PLAYERS,
  UPDATE_PLAYER,
  UPDATE_PLAYER_ATTRIBUTES,
  UPDATE_SCOUTED_PLAYER,
  UPDATE_SCOUTING_REPORT,
  useInvalidate,
} from "./keys";
import {
  DepthChart,
  DepthChartPlayer,
  DepthChartPositionList,
  Folder,
  MatchReport,
  Note,
  Player,
  PlayerAttributes,
  PlayerAttributesCreate,
  PlayerCreate,
  PlayerList,
  PlayerUpdate,
  ResponseDetail,
  ScoutedPlayer,
  ScoutedPlayerCreate,
  ScoutingReport,
  Share,
} from "./types";
import { Dispatch, SetStateAction } from "react";
import { SCOUTING_DEPTH_ROUTE, SCOUTING_PLAYERS_ROUTE } from "../routes";
import {
  SEARCH_PARAMS_DEPTH_CHART_ID,
  SEARCH_PARAMS_LIST_ID,
} from "../constants";
import {
  SEVERITY_ERROR,
  SEVERITY_SUCCESS,
  useAlertContext,
} from "../contexts/AlertContext";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AxiosError } from "axios";
import { DEPTH_CHARTS } from "./endpoints";
import { useAPIContext } from "../contexts/APIContext";
import { useListContext } from "../contexts/ListContext";
import { useMutation } from "react-query";
import { useOktaAuthContext } from "../contexts/OktaAuthContext";

export const LIST_ACTION_PLAYER_ADD = "add";
export const LIST_ACTION_PLAYER_REMOVE = "remove";

interface playersMutationParams {
  player: Player;
  playerList: PlayerList | undefined;
}

export const useCreateDepthChart = () => {
  const { apiClient } = useAPIContext();

  const invalidate = useInvalidate();
  const { setAlertOptions } = useAlertContext();

  return useMutation(
    (label: string) =>
      apiClient.createDepthChart({ label: label } as DepthChart),
    {
      mutationKey: CREATE_DEPTH_CHART,
      onSuccess: (result: any, variables: any) => {
        invalidate(GET_DEPTH_CHARTS);
        setAlertOptions({
          message: "created depth chart ",
          link: `${SCOUTING_DEPTH_ROUTE}?${SEARCH_PARAMS_DEPTH_CHART_ID}=${result.id}`,
          messageLink: variables,
          severity: SEVERITY_SUCCESS,
        });
      },
    }
  );
};

export const useCreateDepthChartPlayerMutation = () => {
  const { apiClient } = useAPIContext();
  const { setAlertOptions } = useAlertContext();
  const invalidate = useInvalidate();

  return useMutation(
    (depthChartPlayer: DepthChartPlayer) =>
      apiClient.createDepthChartPlayer(depthChartPlayer),
    {
      mutationKey: CREATE_DEPTH_CHART_PLAYER,
      onSuccess: (result: any, variables: any) => {
        invalidate(GET_DEPTH_CHART);

        setAlertOptions({
          link: `${DEPTH_CHARTS}?${SEARCH_PARAMS_DEPTH_CHART_ID}=${result.id}`,
          message: `added ${variables?.player?.name || "player"}`,
          messageLink: result.label,
          severity: SEVERITY_SUCCESS,
        });
      },
      onSettled: (data, error, variables) => {
        if (error) {
          const axiosErr = error as AxiosError;
          const errorDetail = axiosErr?.response?.data as ResponseDetail;
          setAlertOptions({
            message: errorDetail.detail,
            severity: SEVERITY_ERROR,
          });
        }
      },
    }
  );
};

// Create a new scouted player record
export const useCreateScoutedPlayerMutation = () => {
  const { apiClient } = useAPIContext();
  const invalidate = useInvalidate();
  const { setAlertOptions } = useAlertContext();

  return useMutation(
    (scoutedPlayer: ScoutedPlayerCreate) =>
      apiClient.createScoutedPlayer(scoutedPlayer),
    {
      mutationKey: CREATE_SCOUTED_PLAYER,
      onSuccess: (result: any, variables: any, context: any) => {
        invalidate(GET_SCOUTED_PLAYERS);
        setAlertOptions({
          severity: SEVERITY_SUCCESS,
          message: "Added new scouted player",
        });
      },
    }
  );
};

export const useCreatePlayerMutation = () => {
  const { apiClient } = useAPIContext();
  const invalidate = useInvalidate();
  const { setAlertOptions } = useAlertContext();

  return useMutation((player: PlayerCreate) => apiClient.createPlayer(player), {
    mutationKey: CREATE_PLAYER,
    onSuccess: (result: any, variables: any, context: any) => {
      invalidate(GET_PLAYERS);
      setAlertOptions({
        severity: SEVERITY_SUCCESS,
        message: "created new player",
      });
    },
  });
};

export const useCreatePlayerAttributesMutation = () => {
  const { setAlertOptions } = useAlertContext();
  const { apiClient } = useAPIContext();
  const invalidate = useInvalidate();

  return useMutation(
    (playerAttributes: PlayerAttributesCreate) =>
      apiClient.createPlayerAttributes(playerAttributes),
    {
      mutationKey: CREATE_PLAYER_ATTRIBUTES,
      onSuccess: (result: any, variables: any, context: any) => {
        invalidate(GET_PLAYER_ATTRIBUTES);
        setAlertOptions({
          message: "updated player attributes",
          severity: SEVERITY_SUCCESS,
        });
      },
    }
  );
};

export const useCreateScoutingReportMutation = () => {
  const { apiClient } = useAPIContext();
  const invalidate = useInvalidate();
  const { setAlertOptions } = useAlertContext();

  return useMutation(
    (report: ScoutingReport) => apiClient.createScoutingReport(report),
    {
      mutationKey: CREATE_SCOUTING_REPORT,
      onSuccess: (result: any, variables: any, context: any) => {
        invalidate(GET_PLAYERS);
        invalidate(GET_PLAYER_SCOUTING_REPORTS);
        setAlertOptions({
          severity: SEVERITY_SUCCESS,
          message: "created new report",
        });
      },
    }
  );
};

export const useCreateListMutation = () => {
  const { apiClient } = useAPIContext();
  const { setAlertOptions } = useAlertContext();
  const invalidate = useInvalidate();

  return useMutation(
    (list) => apiClient.createList(list.owner, list.name, list.players),
    {
      mutationKey: CREATE_LIST,
      onSuccess: (result: any, variables: any, context: any) => {
        invalidate(GET_PLAYER);
        invalidate(GET_LISTS);
        invalidate(GET_FOLDERS);
        setAlertOptions({
          link: `${SCOUTING_PLAYERS_ROUTE}?${SEARCH_PARAMS_LIST_ID}=${result.id}`,
          message: "created new list: ",
          messageLink: result.name,
          severity: SEVERITY_SUCCESS,
        });
      },
    }
  );
};

export const useCreateMatchReportMutation = () => {
  const { apiClient } = useAPIContext();
  const { setAlertOptions } = useAlertContext();
  const invalidate = useInvalidate();

  return useMutation(
    (report: MatchReport) => apiClient.createMatchReport(report),
    {
      mutationKey: CREATE_MATCH_REPORT,

      onSuccess: (result: any, variables: any, context: any) => {
        invalidate(GET_PLAYER_MATCH_REPORTS);
        setAlertOptions({
          severity: SEVERITY_SUCCESS,
          message: "created new match report",
        });
      },
    }
  );
};

export const useCreateNoteMutation = () => {
  const { email } = useOktaAuthContext();
  const { apiClient } = useAPIContext();
  const { setAlertOptions } = useAlertContext();
  const invalidate = useInvalidate();

  return useMutation(
    (note: Note) => apiClient.createNote(note.content, note.player_id, email),
    {
      mutationKey: CREATE_NOTE,
      onSuccess: (result: any, variables: any, context: any) => {
        invalidate(GET_PLAYER_NOTES);
        setAlertOptions({
          message: `created note: (${variables.content.substring(0, 20)}...)`,
          severity: SEVERITY_SUCCESS,
        });
      },
    }
  );
};

export const useCreateFolderMutation = () => {
  const { apiClient } = useAPIContext();
  const invalidate = useInvalidate();

  return useMutation((folder: Folder) => apiClient.createFolder(folder), {
    mutationKey: CREATE_FOLDER,
    onSuccess: (result: any, variables: any, context: any) => {
      invalidate(GET_LISTS);
      invalidate(GET_FOLDERS);
    },
  });
};

export const useDeleteDepthChart = () => {
  const { apiClient } = useAPIContext();
  const { setAlertOptions } = useAlertContext();
  const invalidate = useInvalidate();

  return useMutation(
    (depthChart) => apiClient.deleteDepthChart(depthChart.id),
    {
      mutationKey: DELETE_DEPTH_CHART_PLAYER,
      onSuccess: (result: any, variables: any) => {
        invalidate(GET_DEPTH_CHARTS);
        setAlertOptions({
          message: `Deleted "${variables.label}"`,
          severity: SEVERITY_SUCCESS,
        });
      },
    }
  );
};

export const useDeleteDepthChartPlayerMutation = () => {
  const { apiClient } = useAPIContext();
  const { setAlertOptions } = useAlertContext();
  const invalidate = useInvalidate();

  return useMutation(
    (variables) =>
      apiClient.deleteDepthChartPlayer(variables.depthChartPlayerId),
    {
      mutationKey: DELETE_DEPTH_CHART_PLAYER,
      onSuccess: (result: any, variables: any) => {
        invalidate(GET_DEPTH_CHART);

        setAlertOptions({
          message: `removed ${variables.depthChartPlayerName}`,
          severity: SEVERITY_SUCCESS,
        });
      },
    }
  );
};

export const useDeleteFolderMutation = () => {
  const { apiClient } = useAPIContext();
  const invalidate = useInvalidate();

  return useMutation(
    (folder: Folder) => apiClient.deleteFolder(folder.id, folder.owner),
    {
      mutationKey: DELETE_FOLDER,

      onSuccess: (result: any, variables: any, context: any) => {
        invalidate(GET_LISTS);
        invalidate(GET_FOLDERS);
      },
    }
  );
};

export const useDeleteListMutation = () => {
  const { apiClient } = useAPIContext();
  const { listInContext, setListInContext } = useListContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const invalidate = useInvalidate();

  return useMutation(
    (list: PlayerList) => apiClient.deleteList(list.id, list.owner),
    {
      mutationKey: DELETE_LIST,

      onSuccess: (result: any, variables: any, context: any) => {
        invalidate(GET_LISTS);
        invalidate(GET_FOLDERS);
        if (variables.id === listInContext?.id) {
          setListInContext(undefined);
        }
        if (
          window.location.pathname === SCOUTING_PLAYERS_ROUTE &&
          searchParams.get(SEARCH_PARAMS_LIST_ID)
        ) {
          navigate(SCOUTING_PLAYERS_ROUTE);
        }
      },
    }
  );
};

export const useDeleteNoteMutation = () => {
  const { apiClient } = useAPIContext();
  const invalidate = useInvalidate();

  return useMutation((id) => apiClient.deleteNote(id), {
    mutationKey: GET_PLAYER_NOTES,

    onSuccess: (result: any, variables: any, context: any) => {
      invalidate(GET_PLAYER_NOTES);
      invalidate(GET_PLAYER_SCOUTING_REPORTS);
    },
  });
};

export const useDeleteScoutingReportMutation = () => {
  const { apiClient } = useAPIContext();
  const invalidate = useInvalidate();

  return useMutation((id) => apiClient.deleteScoutingReport(id), {
    mutationKey: DELETE_SCOUTING_REPORT,

    onSuccess: (result: any, variables: any, context: any) => {
      invalidate(GET_PLAYER_SCOUTING_REPORTS);
    },
  });
};

export const useShareMutation = () => {
  const { email } = useOktaAuthContext();
  const { apiClient } = useAPIContext();

  const invalidate = useInvalidate();

  return useMutation(
    (share: Share) =>
      apiClient.share(
        share.object_id,
        share.object_type,
        email,
        share.share_with_email
      ),
    {
      mutationKey: GET_SHARES,

      onSuccess: (result: any, variables: any, context: any) => {
        invalidate(GET_SHARES);
        invalidate(GET_NOTIFICATIONS);
      },
    }
  );
};

export const useUnshareMutation = () => {
  const { email } = useOktaAuthContext();
  const { apiClient } = useAPIContext();
  const invalidate = useInvalidate();

  return useMutation(
    (share: Share) =>
      apiClient.unshare(
        share.id,
        email,
        share.share_with_email,
        share.object_id,
        share.object_type
      ),
    {
      mutationKey: GET_SHARES,

      onSuccess: (result: any, variables: any, context: any) => {
        invalidate(GET_SHARES);
        invalidate(GET_NOTIFICATIONS);
      },
    }
  );
};

export const useUpdateDepthChart = () => {
  const { apiClient } = useAPIContext();
  const invalidate = useInvalidate();

  return useMutation(
    (depthChart: DepthChart) => apiClient.updateDepthChart(depthChart),
    {
      mutationKey: UPDATE_DEPTH_CHART,
      onSuccess: () => {
        invalidate(GET_DEPTH_CHART);
      },
    }
  );
};

export const useUpdateDepthChartPlayer = (depthChartId: number | undefined) => {
  const { apiClient } = useAPIContext();
  const invalidate = useInvalidate();

  return useMutation(
    (depthChartPlayer: DepthChartPlayer) => {
      // This is important to cancel any existing GET_DEPTH_CHART queries so we don't load an old state of the depth chart after a quick, subsequent change
      // Looks very bad visually
      // if (depthChartPlayer.depth_chart_id) {
      //   queryClient.cancelQueries({
      //     queryKey: [GET_DEPTH_CHART, { id: depthChartPlayer.depth_chart_id }],
      //   });
      // }

      return apiClient.updateDepthChartPlayer(depthChartPlayer);
    },
    {
      mutationKey: UPDATE_DEPTH_CHART_PLAYER,
      onSuccess: (result: any, variables: any, context: any) => {
        invalidate(GET_DEPTH_CHART);
      },
    }
  );
};

export const useUpdateDepthChartPositionList = () => {
  const { apiClient } = useAPIContext();
  const invalidate = useInvalidate();

  return useMutation(
    (depthChartPositionList: DepthChartPositionList) => {
      // // This is important to cancel any existing GET_DEPTH_CHART queries so we don't load an old state of the depth chart after a quick, subsequent change
      // // Looks very bad visually
      // if (depthChartPositionList.depth_chart_id) {
      //   queryClient.cancelQueries({
      //     queryKey: [GET_DEPTH_CHART],
      //   });
      // }

      return apiClient.updateDepthChartPositionList(depthChartPositionList);
    },
    {
      mutationKey: UPDATE_DEPTH_CHART_POSITION_LIST,
      onSuccess: (result: any, variables: any, context: any) => {
        invalidate(GET_DEPTH_CHART);
      },
    }
  );
};

export const useUpdateFolderMutation = () => {
  const { apiClient } = useAPIContext();
  const invalidate = useInvalidate();

  return useMutation((folder: Folder) => apiClient.updateFolder(folder), {
    mutationKey: UPDATE_FOLDER,

    onSuccess: (result: any, variables: any, context: any) => {
      invalidate(GET_LISTS);
      invalidate(GET_FOLDERS);
    },
  });
};

export const useUpdateDepthChartMutation = () => {
  // CONTEXT
  const { apiClient } = useAPIContext();
  const invalidate = useInvalidate();

  return useMutation(
    (depthChart: DepthChart) => apiClient.updateDepthChart(depthChart),
    {
      mutationKey: UPDATE_DEPTH_CHART,
      onSuccess: () => {
        invalidate(GET_DEPTH_CHART);
      },
    }
  );
};

export const useRenameListMutation = () => {
  // CONTEXT
  const { apiClient } = useAPIContext();
  const { email } = useOktaAuthContext();
  const invalidate = useInvalidate();

  return useMutation(
    (list: PlayerList) => apiClient.updateListName(list.id, list.name, email),
    {
      mutationKey: UPDATE_LIST,

      onSuccess: (result: any, variables: any, context: any) => {
        invalidate(GET_LISTS);
        invalidate(GET_FOLDERS);
      },
    }
  );
};

export const useUpdateListFolderMutation = () => {
  const { apiClient } = useAPIContext();
  const { setAlertOptions } = useAlertContext();
  const invalidate = useInvalidate();

  return useMutation((list) => apiClient.updateListFolder(list), {
    mutationKey: UPDATE_LIST,

    onSuccess: (result: any, variables: any, context: any) => {
      //   console.debug(variables);
      const message = "Moved ";
      const link = `${SCOUTING_PLAYERS_ROUTE}?${SEARCH_PARAMS_LIST_ID}=${variables.id}`;
      setAlertOptions({
        link: link,
        message: message,
        messageLink: variables.name,
        severity: SEVERITY_SUCCESS,
      });
      invalidate(GET_LISTS);
      invalidate(GET_FOLDERS);
    },
  });
};

export const useUpdateListPlayerMutation = (action: string) => {
  const { apiClient } = useAPIContext();
  const { email } = useOktaAuthContext();
  const { setAlertOptions } = useAlertContext();
  const invalidate = useInvalidate();

  return useMutation(
    ({ player, playerList }: playersMutationParams) => {
      if (!playerList) {
        throw Error("no player list specified!");
      }

      // console.debug(player);
      // console.debug(playerList);
      return apiClient.updateListPlayers(action, playerList.id, email, [
        player,
      ]);
    },
    {
      mutationKey: UPDATE_LIST_PLAYERS,
      onSuccess: (result: any, variables: any, context: any) => {
        // console.debug(result);
        // console.debug(variables);
        // console.debug(context);

        invalidate(GET_LIST);
        invalidate(GET_LISTS);
        invalidate(GET_FOLDERS);
        invalidate(GET_PLAYERS);

        setAlertOptions({
          link: `${SCOUTING_PLAYERS_ROUTE}?${SEARCH_PARAMS_LIST_ID}=${variables.playerList?.id}`,
          message: `${
            action === LIST_ACTION_PLAYER_ADD ? "added" : "removed"
          } ${variables.player.name.toUpperCase()} ${
            action === LIST_ACTION_PLAYER_ADD ? "to" : "from"
          } `,
          messageLink: variables.playerList?.name,
          severity: SEVERITY_SUCCESS,
        });
      },
    }
  );
};

export const useUpdateListPlayersMutation = () => {
  const { apiClient } = useAPIContext();
  const { setAlertOptions } = useAlertContext();
  const invalidate = useInvalidate();

  return useMutation(
    (list) =>
      apiClient.updateListPlayers(
        list.action,
        list.id,
        list.owner,
        list.players
      ),
    {
      mutationKey: UPDATE_LIST,
      onSuccess: (result: any, variables: any, context: any) => {
        invalidate(GET_LIST);
        invalidate(GET_LISTS);
        invalidate(GET_FOLDERS);
        invalidate(GET_PLAYERS);

        const formattedAction =
          variables.action === "remove" ? "removed" : "added";
        const preposition = variables.action === "remove" ? "from" : "to";
        const message = `${formattedAction} player(s) ${preposition}`;
        const link = `${SCOUTING_PLAYERS_ROUTE}?${SEARCH_PARAMS_LIST_ID}=${variables.id}`;

        setAlertOptions({
          link: link,
          message: message,
          messageLink: variables.name,
          severity: SEVERITY_SUCCESS,
        });
      },
    }
  );
};

export const useUpdateNoteMutation = () => {
  const { apiClient } = useAPIContext();
  const invalidate = useInvalidate();

  return useMutation((note: Note) => apiClient.updateNote(note), {
    mutationKey: GET_PLAYER_NOTES,

    onSuccess: (result: any, variables: any, context: any) => {
      invalidate(GET_PLAYER_NOTES);
    },
  });
};

export const useUpdatePlayerMutation = () => {
  const { apiClient } = useAPIContext();
  const invalidate = useInvalidate();

  return useMutation((player: PlayerUpdate) => apiClient.updatePlayer(player), {
    mutationKey: UPDATE_PLAYER,
    onSuccess: (result: any, variables: any, context: any) => {
      invalidate(GET_PLAYER);
      invalidate(GET_LIST);
      invalidate(GET_PLAYERS);
    },
    retry: false,
  });
};

export const useUpdatePlayerAttributesMutation = () => {
  const { setAlertOptions } = useAlertContext();
  const { apiClient } = useAPIContext();
  const invalidate = useInvalidate();

  return useMutation(
    (playerAttributes: PlayerAttributes) =>
      apiClient.updatePlayerAttributes(playerAttributes),
    {
      mutationKey: UPDATE_PLAYER_ATTRIBUTES,
      onSuccess: (result: any, variables: any, context: any) => {
        invalidate(GET_PLAYER_ATTRIBUTES);

        setAlertOptions({
          message: "updated player attributes",
          severity: SEVERITY_SUCCESS,
        });
      },
    }
  );
};

export const useUpdateScoutedPlayerMutation = () => {
  const { apiClient } = useAPIContext();
  const invalidate = useInvalidate();

  return useMutation(
    (scoutedPlayer: ScoutedPlayer) =>
      apiClient.updateScoutedPlayer(scoutedPlayer),
    {
      mutationKey: UPDATE_SCOUTED_PLAYER,
      onSuccess: (result: any, variables: any, context: any) => {
        invalidate(GET_SCOUTED_PLAYERS);
        invalidate(GET_SCOUTING_REPORTS);
      },
      retry: false,
    }
  );
};

export const useUpdateScoutingReportMutation = (
  setReportInContext: Dispatch<SetStateAction<ScoutingReport | undefined>>
) => {
  const { apiClient } = useAPIContext();
  const invalidate = useInvalidate();
  const { setAlertOptions } = useAlertContext();

  return useMutation(
    (report: ScoutingReport) =>
      apiClient.updateScoutingReport(report.id, report),
    {
      mutationKey: UPDATE_SCOUTING_REPORT,

      onSuccess: (result: any, variables: any, context: any) => {
        setReportInContext(result as ScoutingReport);
        invalidate(GET_PLAYER_NOTES);
        invalidate(GET_SCOUTING_REPORTS);
        invalidate(GET_PLAYER_SCOUTING_REPORTS);

        setAlertOptions({
          message: "updated scouting report",
          severity: SEVERITY_SUCCESS,
        });
      },
    }
  );
};
