/* eslint-disable */
import { Box, Chip, IconButton, Tooltip, useMediaQuery } from "@mui/material";
import { useReducer, useRef, useState } from "react";

import { APPBAR_HEIGHT_AS_NUM } from "../../../app_bar/Toolbar";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useDepthChartContext } from "../../../contexts/DepthChartContext";
import { useThemeContext } from "../../../contexts/CustomThemeContext";
import LoadingSpinnerOverlay from "../../../components/LoadingSpinnerOverlay";
import PitchPlayersContext from "./PitchPlayersDragDropContext";

export const CREATE_ACTION = "create";
export const UPDATE_ACTION = "update";

export const GREEN = "#4c7";
export const LIGHT_GREEN = "#5d8";

export default function Pitch() {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  // CONTEXT
  const { theme } = useThemeContext();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { depthChartQuery, depthChartInContext, isLoadingDepthChart } =
    useDepthChartContext();
  const pitchRef = useRef<Element>(null);

  // PITCH DIMENSIONS
  // ASSUME PITCH IS 90 x 120 (width x height): so a ratio of 3 / 4

  // const EFFECTIVE_PITCH_WIDTH = fullScreen ? "100vw" : "80vw";
  const EFFECTIVE_PITCH_WIDTH = fullScreen ? "100vw" : "90vw";
  const EFFECTIVE_PITCH_HEIGHT = `calc(${
    fullScreen ? "100vh" : "95vh"
  } - ${APPBAR_HEIGHT_AS_NUM}px)`;

  const POSITIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  // BORDERS
  const BORDER_WIDTH = "3px";
  const BORDER = `${BORDER_WIDTH} solid white`;

  // SIX YARD BOX DIMENSIONS
  const SIX_YARD_BOX_HEIGHT_RATIO = "6 / 120";
  const SIX_YARD_BOX_WIDTH_RATIO = "20 / 90";
  const SIX_YARD_BOX_WIDTH = `calc((${SIX_YARD_BOX_WIDTH_RATIO}) * ${EFFECTIVE_PITCH_WIDTH})`;
  const SIX_YARD_BOX_HEIGHT = `calc((${SIX_YARD_BOX_HEIGHT_RATIO}) * ${EFFECTIVE_PITCH_HEIGHT})`;
  const SIX_YARD_BOX_OFFSET = `calc((${EFFECTIVE_PITCH_WIDTH} - ${SIX_YARD_BOX_WIDTH} - 8px) / 2)`;

  // EIGHTEEN YARD BOX
  const EIGHTTEN_HEIGHT_RATIO = "18 / 120";
  const EIGHTEEN_HEIGHT = `calc(${EIGHTTEN_HEIGHT_RATIO} * ${EFFECTIVE_PITCH_HEIGHT})`;
  const EIGHTEEN_WIDTH_RATIO = fullScreen ? "55 / 90" : "44 / 90";
  const EIGHTEEN_WIDTH = `calc(${EIGHTEEN_WIDTH_RATIO} * ${EFFECTIVE_PITCH_WIDTH})`;
  const EIGHTEEN_OFFSET = `calc((${EFFECTIVE_PITCH_WIDTH} - ${EIGHTEEN_WIDTH} - 8px) / 2)`;

  // HALF CIRCLES
  const HALF_CIRCLE_RADIUS_RATIO_START = fullScreen ? "15 / 120" : "17 / 120";
  const HALF_CIRCLE_RADIUS_RATIO_STOP = fullScreen
    ? "15.3 / 120"
    : "17.3 / 120";
  const HALF_CIRCLE_RADIUS_START = `calc(${HALF_CIRCLE_RADIUS_RATIO_START} * ${EFFECTIVE_PITCH_HEIGHT})`;
  const HALF_CIRCLE_RADIUS_STOP = `calc(${HALF_CIRCLE_RADIUS_RATIO_STOP} * ${EFFECTIVE_PITCH_HEIGHT})`;

  // STATE
  const [editMode, setEditMode] = useState<boolean>(false);

  const icon = editMode ? (
    <ClearIcon fontSize="small" sx={{ cursor: "pointer" }} />
  ) : (
    <EditIcon fontSize="small" sx={{ cursor: "pointer" }} />
  );

  return (
    // https://stackoverflow.com/questions/68295881/resize-soccer-pitch-to-fill-screen-react-css
    // PITCH
    <>
      {depthChartInContext && !editMode && (
        <Chip
          label={depthChartInContext.label}
          sx={{
            display: "inline-flex",
            position: "absolute",
            top: "11.5%",
            left: "6.5%",
            zIndex: 1050,
          }}
        />
      )}

      {depthChartInContext && (
        <Tooltip
          disableFocusListener
          key="edit-depth-chart-tooltip"
          placement="left"
          title={editMode ? "STOP EDITING" : "EDIT DEPTH CHART PLAYERS"}
        >
          <IconButton
            color={editMode ? "secondary" : "primary"}
            onClick={() => setEditMode(!editMode)}
            size="medium"
            sx={{
              display: "inline-flex",
              position: "absolute",
              top: "75px",
              right: "25px",
              zIndex: 1050,
            }}
          >
            {icon}
          </IconButton>
        </Tooltip>
      )}

      <Tooltip
        disableFocusListener
        key="reset-player-positions-tooltip"
        placement="left"
        title="RESET PLAYER POSITIONS"
      >
        <IconButton
          color="primary"
          onClick={() => {
            POSITIONS.forEach((position: number) => {
              localStorage.removeItem(
                `depth-charts-${
                  depthChartInContext?.id || 0
                }-position-${position}-coordinates`
              );
            });
            forceUpdate();
          }}
          size="medium"
          sx={{
            display: "inline-flex",
            position: "absolute",
            top: "120px",
            right: "22px",
            zIndex: 1050,
          }}
        >
          <RestartAltIcon />
        </IconButton>
      </Tooltip>

      <Box
        ref={pitchRef}
        sx={{
          background: GREEN,
          border: `2px white solid`,
          position: "absolute",
          width: EFFECTIVE_PITCH_WIDTH,
          height: EFFECTIVE_PITCH_HEIGHT,
          // border: "0.5vmin solid white",
          backgroundImage: `
                    radial-gradient(circle at 0% 0%, transparent 1%, white 0 1.2%, transparent 0),
                    radial-gradient(circle at 100% 0%, transparent 1%, white 0 1.2%, transparent 0), 
                    radial-gradient(circle at 0% 100%, transparent 1%, white 0 1.2%, transparent 0), 
                    radial-gradient(circle at 100% 100%, transparent 1%, white 0 1.2%, transparent 0), 
                    radial-gradient(circle at 50% 50%, white 1%, transparent 0), 
                    radial-gradient(circle at 50% 50%, transparent 14%, white 0 14.4%, transparent 0), 
                    radial-gradient(circle at 50% 5%, transparent ${HALF_CIRCLE_RADIUS_START}, white 0 ${HALF_CIRCLE_RADIUS_STOP}, transparent 0), 
                    radial-gradient(circle at 50% 95%, transparent ${HALF_CIRCLE_RADIUS_START}, white 0 ${HALF_CIRCLE_RADIUS_STOP}, transparent 0), 
                    linear-gradient(to top, transparent 49.85%, white 0 50.15%, transparent 0), 
                    repeating-linear-gradient(to top, ${GREEN} 0 10%, ${LIGHT_GREEN} 0 20%)
                `,
          paddingBottom: 0,
        }}
      >
        {/* Top 18 yard box */}
        <Box
          style={{
            background: GREEN,
            position: "absolute",
            top: "0%",
            left: EIGHTEEN_OFFSET,
            width: EIGHTEEN_WIDTH,
            height: EIGHTEEN_HEIGHT,
            border: BORDER,
            borderTop: "0",
          }}
        />
        {/* Top 6 yard box */}
        <Box
          style={{
            background: GREEN,
            position: "absolute",
            top: "0%",
            left: SIX_YARD_BOX_OFFSET,
            width: SIX_YARD_BOX_WIDTH,
            height: SIX_YARD_BOX_HEIGHT,
            border: BORDER,
            borderTop: "0",
          }}
        />
        {/* Bottom 18 yard box */}
        <Box
          style={{
            background: GREEN,
            position: "absolute",
            top: `calc(${EFFECTIVE_PITCH_HEIGHT} - ${EIGHTEEN_HEIGHT} - 4px)`,
            left: EIGHTEEN_OFFSET,
            width: EIGHTEEN_WIDTH,
            height: EIGHTEEN_HEIGHT,
            border: BORDER,
            borderBottom: "0",
          }}
        />
        {/* Bottom 6 yard box */}
        <Box
          style={{
            background: GREEN,
            position: "absolute",
            top: `calc(${EFFECTIVE_PITCH_HEIGHT} - ${SIX_YARD_BOX_HEIGHT} - 4px)`,
            left: SIX_YARD_BOX_OFFSET,
            width: SIX_YARD_BOX_WIDTH,
            height: SIX_YARD_BOX_HEIGHT,
            border: BORDER,
            borderBottom: "0",
          }}
        />
        {/* LOADING SPINNER if depth chart is being fetched */}
        {(isLoadingDepthChart && (
          <LoadingSpinnerOverlay height={"35vh"} size={100} disableShrink />
        )) || (
          <PitchPlayersContext
            editMode={editMode}
            setEditMode={setEditMode}
            pitchRef={pitchRef}
          />
        )}
      </Box>
    </>
  );
}
