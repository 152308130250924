import { Box, Link, ListItemText, Stack, Typography } from "@mui/material";
import { Note, Player, PlayerList } from "../../api/types";
import { SCOUTING_DEPTH_ROUTE } from "../../routes";
import { usePlayerNotes } from "../../api/queries";
import { useThemeContext } from "../../contexts/CustomThemeContext";
import { useSearchParams } from "react-router-dom";
import {
  SEARCH_PARAMS_LIST_ID,
  SEARCH_PARAMS_NOTE_ID,
  SEARCH_PARAMS_PLAYER_ID,
} from "../../constants";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import moment from "moment";
import { useWindowSizeContext } from "../../contexts/WindowSizeContext";

interface PlayerProfileSideBarProps {
  player: Player;
}

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");

export const PlayerProfileSideBar = (props: PlayerProfileSideBarProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  // CONTEXTS
  const { theme } = useThemeContext();
  const { height } = useWindowSizeContext();

  const playerNotesQuery = usePlayerNotes(props.player.id);
  const notes = playerNotesQuery.data;

  // FUNCTIONS
  const onNoteLinkClick = (note: Note) => {
    searchParams.set(SEARCH_PARAMS_PLAYER_ID, String(note.player_id));
    searchParams.set(SEARCH_PARAMS_NOTE_ID, String(note.id));
    setSearchParams(searchParams);
  };

  const onListLinkClick = (list: PlayerList) => {
    searchParams.delete(SEARCH_PARAMS_PLAYER_ID);
    searchParams.set(SEARCH_PARAMS_LIST_ID, String(list.id));
    setSearchParams(searchParams);
  };

  return (
    <Box
      sx={{
        color: `${theme.palette.primary.main} !important`,
      }}
    >
      <Box pb={4} maxHeight={height / 3}>
        <Typography variant={"body1"} pb={1} fontWeight={500}>
          NOTES
        </Typography>
        <Stack spacing={1}>
          {notes &&
            notes.map((n) => {
              return (
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography
                    key={n.id}
                    variant={"david"}
                    noWrap
                    textOverflow={"ellipsis"}
                  >
                    {`• `}
                    <Link
                      onClick={() => onNoteLinkClick(n)}
                      sx={{ cursor: "pointer" }}
                    >
                      {n.content}
                    </Link>
                  </Typography>
                  <Box display={"flex"} justifyContent={"space-between"} pl={1}>
                    <Typography variant={"david"} fontStyle={"italic"}>
                      {n.created_by}
                    </Typography>
                    <Typography variant={"david"}>
                      {timeAgo.format(moment.utc(n.updated_at).toDate())}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
        </Stack>
      </Box>
      <Box pb={4} maxHeight={height / 3}>
        <Typography variant={"body1"} pb={1} fontWeight={500}>
          LISTS
        </Typography>
        <Stack spacing={1}>
          {props.player.lists &&
            props.player.lists.map((list: PlayerList) => {
              return (
                <Typography
                  key={list.id}
                  variant={"david"}
                  noWrap
                  textOverflow={"ellipsis"}
                >
                  {`• `}
                  <Link
                    onClick={() => onListLinkClick(list)}
                    sx={{ cursor: "pointer" }}
                  >
                    {list.name}
                  </Link>
                </Typography>
              );
            })}
        </Stack>
      </Box>
      <Box maxHeight={height / 3}>
        <Typography variant={"body1"} pb={1} fontWeight={500}>
          DEPTH CHARTS
        </Typography>
        <Stack spacing={1}>
          {props.player.depth_charts?.map((dc) => {
            return (
              <Link
                href={`${SCOUTING_DEPTH_ROUTE}?depth_chart_id=${dc.id}`}
                key={dc.id}
                sx={{ cursor: "pointer" }}
              >
                <ListItemText
                  primary={
                    <Typography variant={"david"}>{dc.label}</Typography>
                  }
                  sx={{ m: 0 }}
                />
              </Link>
            );
          })}
        </Stack>
      </Box>
    </Box>
  );
};
