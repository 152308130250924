import { OktaAuthOptions } from "@okta/okta-auth-js";

export const isProduction = process.env.NODE_ENV === "production";

export const redirectUri = isProduction
  ? "https://" + window.location.hostname + "/login/callback"
  : "http://localhost:3000/login/callback";

export const oktaConfig: OktaAuthOptions = {
  issuer: process.env.REACT_APP_OKTA_ISSUER as string,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: redirectUri,
  scopes: ["openid", "profile", "email", "offline_access"],
};
