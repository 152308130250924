import { Dialog, DialogContent, useMediaQuery } from "@mui/material";

import CloseButton from "../buttons/CloseButton";
import PlayerProfile from "../PlayerProfile";
import { SEARCH_PARAMS_PLAYER_ID } from "../../constants";
import { usePlayerContext } from "../../contexts/PlayerContext";
import { useSearchParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";

export default function PlayerProfileDialog() {
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("md"));
  const [searchParams, setSearchParams] = useSearchParams();
  const { playerInContext, playerRating, setPlayerInContext, setPlayerRating } =
    usePlayerContext();

  // STATE
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    searchParams.delete(SEARCH_PARAMS_PLAYER_ID);
    setSearchParams(searchParams);
    setPlayerInContext(undefined);
    setPlayerRating(undefined);
  };

  // USEEFFECT
  useEffect(() => {
    setOpen(!!searchParams.get(SEARCH_PARAMS_PLAYER_ID));
  }, [searchParams]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        style: {
          borderRadius: 0,
          maxWidth: "100%",
        },
      }}
      fullScreen={true}
      sx={{
        zIndex: open ? theme.zIndex.appBar + 1 : -1,
      }}
    >
      <CloseButton
        position="absolute"
        top={10}
        left={10}
        onClick={() => handleClose()}
      />

      <DialogContent
        sx={{
          padding: `0 ${isScreenSmall ? 1 : 20}px 0 ${
            isScreenSmall ? 1 : 20
          }px`,
        }}
      >
        {playerInContext && (
          <PlayerProfile player={playerInContext} playerRating={playerRating} />
        )}
      </DialogContent>
    </Dialog>
  );
}
