import { Folder as FolderType, PlayerList } from "../api/types";

import FolderIcon from "./FolderIcon";
import FolderLabel from "./FolderLabel";
import FolderOpenTwoToneIcon from "@mui/icons-material/FolderOpenTwoTone";
import FolderSharedTwoToneIcon from "@mui/icons-material/FolderSharedTwoTone";
import FolderTwoToneIcon from "@mui/icons-material/FolderTwoTone";
import NavList from "./NavList";
import { TreeItem } from "./TreeItem";
import { useState } from "react";

export default function Folder(props: { index: number; folder: FolderType }) {
  const [open, setOpen] = useState<boolean>(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  let icon = open ? (
    <FolderOpenTwoToneIcon
      key={`${props.folder.id}-${props.index}-folder-open-icon`}
      fontSize="small"
      sx={{
        width: "1.5rem",
      }}
    />
  ) : (
    <FolderTwoToneIcon
      key={`${props.folder.id}-${props.index}-folder-icon`}
      fontSize="small"
      sx={{
        width: "1.5rem",
      }}
    />
  );

  if (props.folder.id === -1) {
    icon = (
      <FolderSharedTwoToneIcon
        key={`${props.folder.id}-${props.index}-folder-off`}
        fontSize="small"
        sx={{
          width: "1.5rem",
        }}
      />
    );
  }

  return (
    <TreeItem
      itemId={`${props.index}-${props.folder.id}-folder-treeview`}
      icon={() => (
        <FolderIcon
          icon={icon}
          name={props.folder.name}
          count={props.folder.lists.length}
        />
      )}
      label={<FolderLabel index={props.index} folder={props.folder} />}
      onClick={toggleOpen}
    >
      {props.folder?.lists?.map((list: PlayerList, index) => {
        return (
          <NavList
            key={props.folder.name}
            folder={props.folder}
            folderIndex={props.index}
            index={index}
            list={list}
          />
        );
      })}
    </TreeItem>
  );
}
