import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Player, PlayerRating } from "../api/types";
import { usePlayer, usePlayerRatings } from "../api/queries";

import { SEARCH_PARAMS_PLAYER_ID } from "../constants";
import { useSearchParams } from "react-router-dom";

export interface PlayerContextInterface {
  playerInContext: Player | undefined;
  playerRating: PlayerRating | undefined;
  setPlayerInContext: Dispatch<SetStateAction<Player | undefined>>;
  setPlayerRating: Dispatch<SetStateAction<PlayerRating | undefined>>;
}

const defaultState = {
  playerInContext: undefined,
  playerRating: undefined,
  setPlayerInContext: () => {},
  setPlayerRating: () => {},
};

const PlayerContext = createContext<PlayerContextInterface>(defaultState);

export const PlayerProvider = ({ children }: { children: React.ReactNode }) => {
  const [searchParams] = useSearchParams();
  const playerId = Number(searchParams.get(SEARCH_PARAMS_PLAYER_ID));

  const [playerInContext, setPlayerInContext] = useState<Player | undefined>();
  const [playerRating, setPlayerRating] = useState<PlayerRating | undefined>();
  const playerQueryResult = usePlayer(!!playerId, playerId);
  const playerRatingsQuery = usePlayerRatings(!!playerId, playerId);

  useEffect(() => {
    if (playerQueryResult.data) {
      setPlayerInContext(playerQueryResult.data as Player);
    }
  }, [playerQueryResult.data]);

  useEffect(() => {
    const regex = /Game Model/;
    if (playerRatingsQuery?.data) {
      const sortedRatings = playerRatingsQuery?.data
        .filter((playerRating) => regex.test(playerRating.rating_profile))
        .sort((a: PlayerRating, b: PlayerRating) => {
          // Sorting player rating records.
          // Take the one from the most recent season. If there are multiple records from the most recent season, take the one with more minutes played. If there's a tie, then take the one with the higher rating.
          if (a.season_name < b.season_name) {
            return -1;
          } else if (a.season_name > b.season_name) {
            return 1;
          } else if (a.minutes < b.minutes) {
            return -1;
          } else if (a.minutes > b.minutes) {
            return 1;
          } else
            return (
              Number(a.weighted_overall_rating) -
              Number(b.weighted_overall_rating)
            );
        });
      const rating = sortedRatings.reverse()[0];
      // sort the data by season name such that we always use the most recent season (for now)
      setPlayerRating(rating);
    }
  }, [playerRatingsQuery?.data]);

  const value = {
    playerInContext: playerInContext,
    playerRating: playerRating,
    setPlayerInContext: setPlayerInContext,
    setPlayerRating: setPlayerRating,
  };

  return (
    <PlayerContext.Provider value={value}>{children}</PlayerContext.Provider>
  );
};

export const usePlayerContext = () => useContext(PlayerContext);
