import {
  Box,
  Button,
  DialogContent,
  DialogContentText,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { DepthChart, DepthChartPlayer, Player } from "../../api/types";
import { Dispatch, SetStateAction, useState } from "react";

import CloseButton from "../buttons/CloseButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useCreateDepthChartPlayerMutation } from "../../api/mutations";
import { useDepthCharts } from "../../api/queries";
import { useThemeContext } from "../../contexts/CustomThemeContext";

interface DepthChartSelectionDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  player: Player | undefined;
}

export function DepthChartSelectionDialog(
  props: DepthChartSelectionDialogProps
) {
  const { theme } = useThemeContext();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const depthCharts = useDepthCharts();
  const addPlayerToDepthChart = useCreateDepthChartPlayerMutation();
  const [depthChart, setDepthChart] = useState<DepthChart>();
  const [search, setSearch] = useState<string | undefined>();
  const [position, setPosition] = useState<string>();

  const [selectedIndex, setSelectedIndex] = useState<number | undefined>();

  const handleListItemClick = (index: number) => {
    setSelectedIndex(index);
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const handlePositionChange = (event: SelectChangeEvent) => {
    setPosition(event.target.value as string);
  };

  const handleSubmit = (
    depthChart: DepthChart,
    player: Player | undefined | null
  ) => {
    if (player) {
      addPlayerToDepthChart.mutate({
        depth_chart_id: depthChart.id,
        player_id: player.id,
        position: position,
        list_index: 0,
        player: player,
      } as DepthChartPlayer);
      props.setOpen(false);
    }
  };

  const ableToSubmit = position && depthChart;

  return (
    <Dialog
      onClose={handleClose}
      open={props.open}
      fullScreen={isScreenSmall}
      PaperProps={{
        width: isScreenSmall ? "100vw" : "60vw",
        height: "fit-content",
      }}
    >
      <CloseButton
        top={10}
        position="absolute"
        onClick={() => props.setOpen(false)}
      />

      <DialogTitle justifyContent="center" alignItems="center" display="flex">
        Select Depth Chart
      </DialogTitle>

      <DialogContent>
        <FormControl size="small" fullWidth>
          <InputLabel id="position-select">Position</InputLabel>
          <Select
            labelId="position-select"
            id="set-position-select"
            value={position}
            label="Position"
            onChange={handlePositionChange}
          >
            <MenuItem value={""} />
            <MenuItem value={"1"}>1</MenuItem>
            <MenuItem value={"2"}>2</MenuItem>
            <MenuItem value={"3"}>3</MenuItem>
            <MenuItem value={"4"}>4</MenuItem>
            <MenuItem value={"5"}>5</MenuItem>
            <MenuItem value={"6"}>6</MenuItem>
            <MenuItem value={"7"}>7</MenuItem>
            <MenuItem value={"8"}>8</MenuItem>
            <MenuItem value={"9"}>9</MenuItem>
            <MenuItem value={"10"}>10</MenuItem>
            <MenuItem value={"11"}>11</MenuItem>
          </Select>
        </FormControl>

        <DialogContentText>
          <List disablePadding>
            <TextField
              id="outlined-search"
              fullWidth
              label="Search"
              type="search"
              size="small"
              onChange={(event) => setSearch(event.target.value)}
              sx={{ marginTop: 1, width: "100%" }}
            />

            {depthCharts &&
              depthCharts?.data &&
              depthCharts?.data
                .filter((depthChart: DepthChart) =>
                  search
                    ? depthChart &&
                      depthChart?.label &&
                      depthChart?.label
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    : true
                )
                .sort((a: any, b: any) => a.label.localeCompare(b.label))
                .map((depthChart: DepthChart, index: number) => {
                  return (
                    <ListItem
                      dense
                      disableGutters
                      disablePadding
                      key={depthChart.label}
                    >
                      <ListItemButton
                        onClick={() => {
                          setDepthChart(depthChart);
                          handleListItemClick(index);
                        }}
                        selected={selectedIndex === index}
                      >
                        <ListItemText
                          primary={depthChart.label}
                          primaryTypographyProps={{ noWrap: true }}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
          </List>

          <Tooltip
            title={
              ableToSubmit ? "" : "Please select a Position and Depth Chart"
            }
          >
            <Box display="flex" justifyContent="center">
              <Button
                disabled={!ableToSubmit}
                variant="contained"
                disableElevation
                onClick={() =>
                  depthChart && handleSubmit(depthChart, props.player)
                }
                sx={{
                  marginTop: 1,
                }}
              >
                Submit
              </Button>
            </Box>
          </Tooltip>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
