import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Grid2 as Grid,
  Rating,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { MatchReport, ReportQualityRating } from "../../api/types";
import { useEffect, useMemo, useState } from "react";

import CircleIcon from "@mui/icons-material/Circle";
import { ReportGroupData } from "../../constants";
import StarIcon from "@mui/icons-material/Star";
import { toTitle } from "../../utils/stringManipulation";
import { useMatchReportDialogContext } from "../../contexts/MatchReportDialogContext";
import { useThemeContext } from "../../contexts/CustomThemeContext";

interface MatchReportItemProps {
  report: MatchReport;
}

export const MatchReportItem = (props: MatchReportItemProps) => {
  // CONTEXT
  const { theme } = useThemeContext();
  const { handleReportClick } = useMatchReportDialogContext();

  // HOOKS
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [averageRatings, setAverageRatings] = useState<ReportGroupData[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // MEMO
  const AverageRatings = useMemo(() => {
    if (averageRatings) {
      return averageRatings.map((r) => {
        return (
          <Stack
            key={`${r.group}`}
            alignItems={"center"}
            p={"4px 16px 4px 16px"}
          >
            <Box display={"flex"} alignItems={"center"}>
              <Typography variant={"caption"} sx={{ fontWeight: 500 }}>
                {toTitle(r.group)}
              </Typography>
            </Box>
            <Box display={"flex"} alignItems={"center"}>
              <Rating
                // size="small"
                sx={{ fontSize: 20 }}
                name="group-rating"
                value={r.average ? r.average : 0}
                precision={0.1}
                readOnly
                max={6}
                emptyIcon={
                  <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                }
              />
              <Typography ml={1} variant={"caption"}>
                {r.average ? r.average : ""}
              </Typography>
            </Box>
          </Stack>
        );
      });
    }
  }, [averageRatings]);

  // USEEFFECT
  // calculate averages per group
  useEffect(() => {
    // group qualities
    if (props.report.ratings && props.report.ratings.length > 0) {
      const groups = props.report.ratings.reduce((gs, r) => {
        (gs[r.quality.group] = gs[r.quality.group] || []).push(r);

        return gs;
      }, {} as { [key: string]: ReportQualityRating[] });

      // calculate averages and totals
      const groupData = Object.entries(groups).map((g) => {
        let ratingSum = 0;
        let filled = 0;
        g[1].forEach((q) => {
          if (q.rating) {
            ratingSum += q.rating;
            filled++;
          }
        });

        const data = {} as ReportGroupData;
        data["group"] = g[0];
        data["average"] = Math.round((ratingSum / filled) * 100) / 100;
        data["filled"] = filled;
        data["total"] = g[1].length;

        return data;
      });

      setAverageRatings(groupData);

      setIsLoading(false);
    }
  }, [props.report]);

  return (
    <Box
      p={"16px 16px 32px 16px"}
      sx={{
        cursor: "pointer",
        "&:hover": { backgroundColor: "lightgray" },
      }}
      onClick={() => handleReportClick(props.report)}
    >
      {(!isScreenSmall && (
        <>
          <Grid container>
            {props.report.player_name && (
              <Grid size={{ xs: 12 }}>
                <Box
                  p={1}
                  display={"flex"}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Avatar
                    // variant="square"
                    src={props.report.player_image}
                    sx={{
                      height: 36,
                      width: 36,
                    }}
                  />
                  <Typography variant={"h6"} fontWeight={500} pl={2}>
                    {props.report.player_name}
                  </Typography>
                  <Typography pl={2} pr={2}>
                    •
                  </Typography>
                  <Typography fontWeight={400}>
                    {toTitle(props.report.profile)}
                  </Typography>
                </Box>
              </Grid>
            )}
            <Grid
              size={{ xs: 12, md: 3 }}
              display={"flex"}
              alignItems={"center"}
            >
              <Typography variant="body2">
                {new Date(props.report.date).toLocaleString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
              </Typography>
              <Divider
                sx={{ ml: 2, mr: 2 }}
                orientation={"vertical"}
                flexItem
              />
              <Typography variant="body2">
                Position Played: {toTitle(props.report.position).toUpperCase()}
              </Typography>
            </Grid>
            <Grid
              size={{ sm: 12, md: 6 }}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography
                variant="caption"
                ml={0.5}
                mr={0.5}
              >{`(${props.report.player_team_formation})`}</Typography>
              <Typography
                ml={0.5}
                mr={0.5}
                sx={{ textDecoration: "underline" }}
              >
                {props.report.player_team}
              </Typography>
              <Typography ml={0.5} mr={0.5} fontWeight={500}>
                {props.report.player_team_goals}
              </Typography>
              <Typography ml={0.5} mr={0.5} fontWeight={500}>
                -
              </Typography>
              <Typography ml={0.5} mr={0.5} fontWeight={500}>
                {props.report.opposition_goals}
              </Typography>
              <Typography ml={0.5} mr={0.5}>
                {props.report.opposition}
              </Typography>
              <Typography
                ml={0.5}
                mr={0.5}
                variant="caption"
              >{`(${props.report.opposition_formation})`}</Typography>
            </Grid>
            <Grid
              size={{ xs: 12, md: 3 }}
              display={"flex"}
              justifyContent="end"
              alignItems="center"
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography fontWeight="500">{props.report.stance}</Typography>
                <CircleIcon
                  sx={{
                    height: 16,
                    color:
                      props.report.stance === "Pass"
                        ? "red"
                        : props.report.stance === "Monitor"
                        ? "yellow"
                        : "green",
                  }}
                />
              </Box>
              <Divider
                sx={{ ml: 2, mr: 2 }}
                orientation={"vertical"}
                flexItem
              />
              <Typography variant="caption" sx={{ fontStyle: "italic" }}>
                {props.report.scout}
              </Typography>
            </Grid>
          </Grid>
          <Box
            display={"flex"}
            flexWrap={"wrap"}
            justifyContent={"center"}
            mt={2}
            ml={10}
            mr={10}
          >
            {(isLoading && (
              <Box
                sx={{
                  // height: "10vh",
                  // width: "100%",
                  display: "flex",
                  // justifyContent: "center",
                  // alignContent: "center",
                  // alignItems: "center",
                }}
              >
                <CircularProgress disableShrink={true} />
              </Box>
            )) ||
              AverageRatings}
          </Box>
        </>
      )) || (
        <Stack spacing={1}>
          {props.report.player_name && (
            <Grid size={{ xs: 12 }}>
              <Box display={"flex"} justifyContent={"center"}>
                <Typography variant="caption" sx={{ fontStyle: "italic" }}>
                  {props.report.scout}
                </Typography>
              </Box>
              <Box
                p={1}
                display={"flex"}
                justifyContent="center"
                alignItems="center"
              >
                <Avatar
                  // variant="square"
                  src={props.report.player_image}
                  sx={{
                    height: 36,
                    width: 36,
                  }}
                />
                <Typography variant={"body2"} fontWeight={500} pl={2}>
                  {props.report.player_name}
                </Typography>
                <Typography pl={1} pr={1}>
                  •
                </Typography>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography variant={"caption"} fontWeight="500">
                    {props.report.stance}
                  </Typography>
                  <CircleIcon
                    sx={{
                      height: 16,
                      color:
                        props.report.stance === "Pass"
                          ? "red"
                          : props.report.stance === "Monitor"
                          ? "yellow"
                          : "green",
                    }}
                  />
                </Box>
              </Box>
              <Typography
                variant="body2"
                fontWeight={500}
                sx={{ textDecoration: "underline" }}
              >
                {toTitle(props.report.profile)}
              </Typography>
            </Grid>
          )}
          <Grid
            container
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid size={{ xs: 6 }}>
              <Typography variant="body2">
                {new Date(props.report.date).toLocaleString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
              </Typography>
            </Grid>
            <Grid size={{ xs: 6 }}>
              <Typography variant="body2">
                Position Played: {props.report.position.toUpperCase()}
              </Typography>
            </Grid>
          </Grid>
          <>
            <Box display="flex" justifyContent={"center"}>
              <Typography
                variant="caption"
                ml={0.5}
                mr={0.5}
              >{`(${props.report.player_team_formation})`}</Typography>
              <Typography
                ml={0.5}
                mr={0.5}
                sx={{ textDecoration: "underline" }}
              >
                {props.report.player_team}
              </Typography>
              <Typography ml={0.5} mr={0.5} fontWeight={500}>
                {props.report.player_team_goals}
              </Typography>
            </Box>
            <Box display="flex" justifyContent={"center"}>
              <Typography ml={0.5} mr={0.5} variant="caption">
                {`(${props.report.opposition_formation})`}
              </Typography>
              <Typography ml={0.5} mr={0.5}>
                {props.report.opposition}
              </Typography>
              <Typography ml={0.5} mr={0.5} fontWeight={500}>
                {props.report.opposition_goals}
              </Typography>
            </Box>
          </>
        </Stack>
      )}
      <Box mt={1}>
        <Typography variant={"caption"}>{props.report.notes}</Typography>
      </Box>
    </Box>
  );
};
