/*
- onDragEnd, compare droppable id of source and destination
  - if the same, reorder that position list
  - if it's different, reorder both the source and desination position list
  
*/

import {
  DragDropContext,
  DraggableLocation,
  DropResult,
} from "@hello-pangea/dnd";
import PitchPlayer from "./PitchPlayer";
import { DepthChartPlayer } from "../../../api/types";
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useMediaQuery } from "@mui/system";
import { useThemeContext } from "../../../contexts/CustomThemeContext";
import { useDepthChartContext } from "../../../contexts/DepthChartContext";
import {
  useUpdateDepthChartPlayer,
  useUpdateDepthChartPositionList,
} from "../../../api/mutations";

interface PitchPlayersDragDropContextProps {
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  pitchRef: RefObject<Element>;
}

export default function PitchPlayersDragDropContext(
  props: PitchPlayersDragDropContextProps
) {
  // CONTEXT
  const { theme } = useThemeContext();
  const { depthChartInContext } = useDepthChartContext();

  // HOOK
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // MUTATION
  const updateDepthChartPositionList = useUpdateDepthChartPositionList();
  const updateDepthChartPlayer = useUpdateDepthChartPlayer(
    depthChartInContext?.id
  );

  // STATE
  const [positionLists, setPositionLists] =
    useState<Record<string, DepthChartPlayer[]>>();

  // FUNCTION
  const getPlayersByPosition = (depthChartPlayers: DepthChartPlayer[]) => {
    const playersByPosition = depthChartPlayers.reduce(
      (acc, player) => {
        acc[player.position].push(player);
        return acc;
      },
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].reduce(
        (obj, position) => ({
          ...obj,
          [position]: [],
        }),
        {} as Record<string, DepthChartPlayer[]>
      )
    );

    return playersByPosition;
  };

  const reorder = (
    movedPlayer: DepthChartPlayer,
    source: DraggableLocation<string>,
    destination: DraggableLocation<string>
  ): Record<string, DepthChartPlayer[]> | undefined => {
    // if player is moved to a different position
    if (positionLists) {
      if (source.droppableId !== destination.droppableId) {
        // remove from source
        let sourcePositionList = positionLists[source.droppableId];
        sourcePositionList.splice(source.index, 1);
        // reorder indeces and reassign position
        sourcePositionList.forEach((player: DepthChartPlayer, i) => {
          player.list_index = i;
        });

        // update position of movedPlayer
        movedPlayer.position = destination.droppableId;

        // add to destination
        let destinationPositionList = positionLists[destination.droppableId];
        destinationPositionList.splice(destination.index, 0, movedPlayer);
        // reorder indeces
        destinationPositionList.forEach((player: DepthChartPlayer, i) => {
          player.list_index = i;
        });

        // return the updated positionLists
        return {
          ...positionLists,
          [source.droppableId]: sourcePositionList,
          [destination.droppableId]: destinationPositionList,
        };
      }

      // if player is moved within the same position
      else {
        let sourcePositionList = positionLists[source.droppableId];
        sourcePositionList.splice(source.index, 1);
        sourcePositionList.splice(destination.index, 0, movedPlayer);
        // reorder indeces
        sourcePositionList.forEach((player: DepthChartPlayer, i) => {
          player.list_index = i;
        });

        return {
          ...positionLists,
          [source.droppableId]: sourcePositionList,
        };
      }
    }

    return undefined;
  };

  const dragEnd = ({ destination, source }: DropResult) => {
    if (depthChartInContext) {
      if (!destination) {
        return;
      }

      if (positionLists) {
        // Update in front end
        let movedPlayer = positionLists[source.droppableId][source.index];
        const reordered = reorder(movedPlayer, source, destination);

        setPositionLists(reordered);

        // Push changes to backend

        // update source position list
        let sourcePositionList = positionLists[source.droppableId];
        updateDepthChartPositionList.mutate({
          depth_chart_id: depthChartInContext?.id,
          position: source.droppableId,
          position_list: sourcePositionList,
        });

        // if the destination position is different, update the destination position list as well
        if (source.droppableId !== destination.droppableId) {
          let destinationPositionList = positionLists[destination.droppableId];
          updateDepthChartPositionList.mutate({
            depth_chart_id: depthChartInContext?.id,
            position: destination.droppableId,
            position_list: destinationPositionList,
          });
        }
      }
    }
  };

  const updateDCPlayer = (depthChartPlayer: DepthChartPlayer) => {
    if (positionLists) {
      let playerPositionList = positionLists[depthChartPlayer.position];

      let index = null;
      playerPositionList.forEach((p, i) => {
        if (p.id === depthChartPlayer.id) {
          index = i;
        }
      });

      if (index !== null) {
        playerPositionList[index] = depthChartPlayer;

        setPositionLists({
          ...positionLists,
          [depthChartPlayer.position]: playerPositionList,
        });
      }

      // update backend
      updateDepthChartPlayer.mutate(depthChartPlayer);
    }
  };

  // USEEFFECT
  useEffect(() => {
    // once depth chart is loaded, populate each position with its respective players
    if (depthChartInContext && depthChartInContext.depth_chart_players) {
      setPositionLists(
        getPlayersByPosition(depthChartInContext?.depth_chart_players)
      );
    }
  }, [depthChartInContext]);

  return (
    <DragDropContext onDragEnd={dragEnd}>
      {/* Number 1 */}
      <PitchPlayer
        depthChartId={depthChartInContext?.id || 0}
        position={"1"}
        positionList={positionLists && positionLists["1"]}
        updateDepthChartPlayer={updateDCPlayer}
        topOffset="70%"
        leftOffset={fullScreen ? "46%" : "49%"}
        editMode={props.editMode}
        setEditMode={props.setEditMode}
        pitchRef={props.pitchRef}
      />
      {/* Number 2 */}
      <PitchPlayer
        depthChartId={depthChartInContext?.id || 0}
        position={"2"}
        positionList={positionLists && positionLists["2"]}
        updateDepthChartPlayer={updateDCPlayer}
        topOffset="60%"
        leftOffset={fullScreen ? "86%" : "88%"}
        editMode={props.editMode}
        setEditMode={props.setEditMode}
        pitchRef={props.pitchRef}
      />
      {/* Number 3 */}
      <PitchPlayer
        depthChartId={depthChartInContext?.id || 0}
        position={"3"}
        positionList={positionLists && positionLists["3"]}
        updateDepthChartPlayer={updateDCPlayer}
        topOffset="60%"
        leftOffset={fullScreen ? "6%" : "8%"}
        editMode={props.editMode}
        setEditMode={props.setEditMode}
        pitchRef={props.pitchRef}
      />
      {/* Number 4 */}
      <PitchPlayer
        depthChartId={depthChartInContext?.id || 0}
        position={"4"}
        positionList={positionLists && positionLists["4"]}
        updateDepthChartPlayer={updateDCPlayer}
        topOffset="65%"
        leftOffset={fullScreen ? "66%" : "68%"}
        editMode={props.editMode}
        setEditMode={props.setEditMode}
        pitchRef={props.pitchRef}
      />
      {/* Number 5 */}
      <PitchPlayer
        depthChartId={depthChartInContext?.id || 0}
        position={"5"}
        positionList={positionLists && positionLists["5"]}
        updateDepthChartPlayer={updateDCPlayer}
        topOffset="65%"
        leftOffset={fullScreen ? "26%" : "28%"}
        editMode={props.editMode}
        setEditMode={props.setEditMode}
        pitchRef={props.pitchRef}
      />
      {/* Number 6 */}
      <PitchPlayer
        depthChartId={depthChartInContext?.id || 0}
        position={"6"}
        positionList={positionLists && positionLists["6"]}
        updateDepthChartPlayer={updateDCPlayer}
        topOffset="35%"
        leftOffset={fullScreen ? "46%" : "49%"}
        editMode={props.editMode}
        setEditMode={props.setEditMode}
        pitchRef={props.pitchRef}
      />
      {/* Number 7 */}
      <PitchPlayer
        depthChartId={depthChartInContext?.id || 0}
        position={"7"}
        positionList={positionLists && positionLists["7"]}
        updateDepthChartPlayer={updateDCPlayer}
        topOffset="15%"
        leftOffset={fullScreen ? "86%" : "88%"}
        editMode={props.editMode}
        setEditMode={props.setEditMode}
        pitchRef={props.pitchRef}
      />
      {/* Number 8 */}
      <PitchPlayer
        depthChartId={depthChartInContext?.id || 0}
        position={"8"}
        positionList={positionLists && positionLists["8"]}
        updateDepthChartPlayer={updateDCPlayer}
        topOffset="25%"
        leftOffset={fullScreen ? "66%" : "68%"}
        editMode={props.editMode}
        setEditMode={props.setEditMode}
        pitchRef={props.pitchRef}
      />
      {/* Number 9 */}
      <PitchPlayer
        depthChartId={depthChartInContext?.id || 0}
        position={"9"}
        positionList={positionLists && positionLists["9"]}
        updateDepthChartPlayer={updateDCPlayer}
        topOffset="0%"
        leftOffset={fullScreen ? "46%" : "49%"}
        editMode={props.editMode}
        setEditMode={props.setEditMode}
        pitchRef={props.pitchRef}
      />
      {/* Number 10 */}
      <PitchPlayer
        depthChartId={depthChartInContext?.id || 0}
        position={"10"}
        positionList={positionLists && positionLists["10"]}
        updateDepthChartPlayer={updateDCPlayer}
        topOffset="25%"
        leftOffset={fullScreen ? "26%" : "28%"}
        editMode={props.editMode}
        setEditMode={props.setEditMode}
        pitchRef={props.pitchRef}
      />
      {/* Number 11 */}
      <PitchPlayer
        depthChartId={depthChartInContext?.id || 0}
        position={"11"}
        positionList={positionLists && positionLists["11"]}
        updateDepthChartPlayer={updateDCPlayer}
        topOffset="15%"
        leftOffset={fullScreen ? "6%" : "8%"}
        editMode={props.editMode}
        setEditMode={props.setEditMode}
        pitchRef={props.pitchRef}
      />
    </DragDropContext>
  );
}
