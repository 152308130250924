import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Rating,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReportQualityItem from "./ReportQualityRatingItem";
import { ReportQualityRating } from "../../api/types";
import StarIcon from "@mui/icons-material/Star";
import { useThemeContext } from "../../contexts/CustomThemeContext";

interface QualityGroupAccordionProps {
  label: string;
  group: string;
  profile: string;
  expanded: string | false;
  handleAccordionChange: (
    group: string
  ) => (event: React.SyntheticEvent, newExpanded: boolean) => void;
  ratings: ReportQualityRating[];
  updateRating?: (group: string, quality: ReportQualityRating) => void;
  readOnly: boolean;
}

export const QualityGroupAccordion = (props: QualityGroupAccordionProps) => {
  // CONTEXTS
  const { theme } = useThemeContext();

  // HOOKS
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));

  // STATE
  // const [qualities, setQualities] = useState<ReportQualityRating[]>([]);
  const [ratingsSet, setRatingsSet] = useState<number>(0);
  const [average, setAverage] = useState<number | null>();
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    const filledRatings = Object.values(props.ratings).filter(
      (r) => r.rating != null
    );

    // calculate number of ratings filled out
    setRatingsSet(filledRatings.length);

    // calculate average rating
    setAverage(
      Math.round(
        (filledRatings.reduce((sum, r) => {
          if (r.rating) {
            return sum + r.rating;
          } else {
            return sum;
          }
        }, 0) /
          filledRatings.length) *
          100
      ) / 100
    );
  }, [props.ratings]);

  useEffect(() => {
    setExpanded(props.expanded === props.group);
  }, [props.expanded, props.group]);

  return (
    <Accordion
      // ref={ref}
      sx={{
        width: "100%",
        color: theme.palette.primary.main,
        borderColor: theme.palette.info.main,
      }}
      // Contolling expansion greatly improves performance. Everything isn't rendered all at once
      // https://medium.com/@freshmilkdev/reactjs-render-optimization-for-collapsible-material-ui-long-list-with-checkboxes-231b36892e20
      expanded={expanded}
      onChange={props.handleAccordionChange(props.group)}
      slotProps={{
        transition: {
          onEntered: (node: HTMLElement) => {
            if (node && node.parentElement) {
              node.parentElement.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }
          },
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          borderBottom: `1px ${theme.palette.primary.main} solid`,
          ".MuiAccordionSummary-content": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6">{props.label}</Typography>
          <Typography
            sx={{ ml: 1 }}
          >{`${ratingsSet}/${props.ratings.length}`}</Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Rating
            size={isScreenSmall ? "small" : "medium"}
            name="group-rating"
            value={average ? average : 0}
            precision={0.1}
            readOnly
            sx={{ ml: 1 }}
            max={6}
            emptyIcon={
              <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
            }
          />
          <Typography fontWeight={500} sx={{ ml: 1 }}>
            {average ? `(${average})` : ""}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingTop: 2 }}>
        {true && (
          <Stack spacing={2}>
            {Object.entries(props.ratings).map((r, i) => {
              const rating = structuredClone(r[1]);

              return (
                <Box key={`${props.profile}-${rating.quality.key}`}>
                  <ReportQualityItem
                    // key={`${q.profile}-${q.key}`}
                    id={`${props.profile}-${rating.quality.key}`}
                    rating={rating}
                    updateRating={(quality: ReportQualityRating) => {
                      if (props.updateRating) {
                        props.updateRating(props.group, quality);
                      }
                    }}
                    readOnly={props.readOnly}
                  />
                  {i < props.ratings.length - 1 && <Divider />}
                </Box>
              );
            })}
          </Stack>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
