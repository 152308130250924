import {
  EXTRA_EXTRA_LARGE_WIDTH,
  EXTRA_LARGE_WIDTH,
  MEDIUM_WIDTH,
  SMALL_WIDTH,
  withStringOperators,
  withoutStringOperators,
} from "../../utils/dataGridHelpers";
import { calculateAge, getUTCDate } from "../../utils/dates";

import { Avatar } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { useMemo } from "react";

export const hiddenByDefault: GridColDef[] = [
  {
    field: "mls_eligible",
    filterable: true,
    headerName: "MLS Eligible",
    width: SMALL_WIDTH,
    type: "boolean",
    aggregable: false,
  },
  {
    field: "recently_changed",
    headerName: "Recently Changed",
    width: SMALL_WIDTH,
    type: "boolean",
    aggregable: false,
  },
];

export const displayedByDefault: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    width: EXTRA_EXTRA_LARGE_WIDTH,
    aggregable: false,
    filterOperators: withStringOperators,
    renderCell: (params) => {
      // console.debug(params);
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Avatar
            src={params.row.image}
            sx={{ width: 32, height: 32, display: "flex", marginRight: 1 }}
          />
          {params.value}
        </div>
      );
    },
  },
  {
    field: "team",
    headerName: "Current Team",
    width: EXTRA_LARGE_WIDTH,
    aggregable: false,
    filterOperators: withStringOperators,
  },
  {
    field: "birthdate",
    headerName: "Date of Birth",
    width: SMALL_WIDTH,
    type: "date",
    aggregable: false,
    valueGetter: (value) => {
      return getUTCDate(new Date(value));
    },
  },
  {
    field: "nationality",
    headerName: "Nationality",
    width: MEDIUM_WIDTH,
    aggregable: false,
    filterOperators: withStringOperators,
  },
  {
    field: "league",
    headerName: "League",
    width: EXTRA_LARGE_WIDTH,
    aggregable: false,
    filterOperators: withStringOperators,
  },
  {
    field: "position",
    headerName: "Position",
    width: EXTRA_EXTRA_LARGE_WIDTH,
    aggregable: false,
    filterOperators: withStringOperators,
  },
  {
    field: "age",
    headerName: "Age",
    width: SMALL_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
    valueGetter: (value, row) => {
      if (value) {
        return value;
      }
      return calculateAge(row.birthdate);
    },
  },
  {
    field: "tm_value_num",
    headerName: "Market Value",
    width: SMALL_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
    valueFormatter: (value) => {
      if (value) {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
        }).format(value);
      }
      return null;
    },
  },
  {
    field: "current_player_rating",
    headerName: "Player Rating",
    width: EXTRA_LARGE_WIDTH,
    type: "number",
    aggregable: true,
    filterOperators: withoutStringOperators,
    valueFormatter: (value) => {
      if (value) {
        return Math.round(Number(value) * 10) / 10;
      }
      return null;
    },
  },
];

interface ScoutingDataGridColDefsProps {
  colDefs?: string[];
}

export default function ScoutingDataGridColDefs(
  props: ScoutingDataGridColDefsProps
) {
  const columns = useMemo(() => {
    return displayedByDefault
      .concat(hiddenByDefault)
      .filter(
        (value) =>
          props.colDefs &&
          (props.colDefs.includes(value.field) || props.colDefs.includes("*"))
      );
  }, [props.colDefs]);

  return columns;
}
